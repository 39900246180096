import "./index.css"
import MenuIcon from "../../global/MenuIcon"
import { useDestinos } from "./../../hooks/useDestinos"
import { APIURL } from "../../config/siteConfig"
import { useState } from "react"
import { useNavigate } from 'react-router-dom'
// import logoOferta from "./../../assets/destinos/promo.png"

// const DestinoItem = (props) => {
//     const {  src = "", titulo = "", onAction = () => {} } = props
//     return (
//         <div className="DestinoItemMenu-container" onClick={onAction}>
//             <img src={src} alt={titulo} />
//         </div>
//     )
// } 

const DestinoImtenMenu = (props) => {
    const { id=0, src = "", titulo = "" } = props
    const navigate = useNavigate()
    const onAction = () => {
        navigate(`/paquetes/${id}/${titulo}`, { state: { id: id, src: src } })
    }
    return (
        <div className="DestinoItemMenu-container" onClick={onAction}>
            <img src={src} alt={titulo} />
        </div>
    )
}

const DestinosMenu = () => {
    const [destinos] = useDestinos();
    const [active, setActive] = useState(false);
    // const navigate = useNavigate()
    const onToggle = () =>{
        setActive(!active)
    }
    return (
        <div className="DestinosMenu">
            <MenuIcon active={active} onClick={onToggle} />
            <div className={(active) ? "DestinoMenu-icons destinoActivo" : "DestinoMenu-icons destinoDesactive"}>
            <MenuIcon onClick={onToggle}  />
                {/* <DestinoItem 
                    src={logoOferta}
                    titulo="Oferta"
                    onAction={() => {navigate("/paquetes/oferta")}}
                /> */}
                {
                    destinos.map((destino => (
                        <DestinoImtenMenu
                            key={destino.IdCategoriaPaquete}
                            id={destino.IdCategoriaPaquete}
                            src={`${APIURL}/Category/${destino.IdCategoriaPaquete}/Logo`}
                            titulo={destino.Nombre}
                        />
                    )))
                }

            </div>
        </div>
    )
}
export default DestinosMenu;