import { useRef } from "react"
import styles from "./index.module.css"
import { useDetallePaquete } from "../../../hooks/usePaqueteDetalle"
import { useSelector } from "react-redux"
import WhatsappButton from "../WhatsappButton"
import { useScrollBarProgress } from "../hooks/useScrollBarProgress"
const Incluye = () => {
    const { idPaquete } = useSelector(store => store.slider)
    const [detalle, load] = useDetallePaquete({ idPaquete: idPaquete })
    const containerRef = useRef(null)
    const [scrollProgress] = useScrollBarProgress({ containerRef: containerRef })
    return (
        <div>
            <div className={styles.progressContainer}>
                <div className={styles.progressBar} style={{ height: `${scrollProgress}%` }}></div>
            </div>
            <div className={styles.scrollContent} ref={containerRef}>
                {
                    (load) ? <div className={styles.incluye}>Cargando...</div>
                        : <div className={styles.incluye} dangerouslySetInnerHTML={{ __html: `${detalle.Incluye}` }} />
                }
            </div>
            <WhatsappButton />
        </div>
    )
}
export default Incluye