import {useEffect, useState} from "react";
import { getPaqueteOfertLimitAll } from "../services/paqueteService";
export const usePaquetesOfertasLimit = ({Busqueda}) =>{
    const [paquetes, setPaquetes] = useState([]);
    useEffect(() =>{
        Promise.all([getPaqueteOfertLimitAll({Busqueda})]).then(Response => {
            setPaquetes(Response[0]);
        }).catch(Error => {
            setPaquetes([]);
        })
        return () =>{
            setPaquetes([]);
        }
    },[Busqueda])
    return [paquetes];
}