import axios from "axios";
import { APIURL } from "./../config/siteConfig";
import { getCabecera } from "../utils/getCabecera";

const urlSpot = "Spot";

export const getSuplmentosByPaqueteId = async ({idPaquete}) =>{
  return await axios.get(`${APIURL}/${urlSpot}/Package/${idPaquete}`, {
    headers: getCabecera(),
  }).then((Response) => {
    return Response.data;
  });
};