import "./Suplemento.css"
import { formatDinero } from "./../../utils/getDinero"
const Suplemento = (props) => {
    const { Suplementos = [], precioPublico = 0, moneda = "" } = props
    return (
        <div className="SuplementoTable">
            <table>
                <thead>
                    <tr>
                        {
                            Suplementos.map((sup, index) => <th key={index}>
                                {sup.TipoPlaza.Descripcion}
                            </th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {
                            Suplementos.map((suplemento) => {
                                return (<td key={suplemento.TipoPlaza.IdTipoPlaza}>
                                    {formatDinero((suplemento?.TipoPlaza?.IdTipoPlaza === 8) ? suplemento.Suplemento : precioPublico + suplemento.Suplemento)} {moneda}
                                </td>)
                            })
                        }
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default Suplemento;