import {useEffect, useState} from "react"
import { getPaqueteGaleria } from "../services/paqueteService"
export const useGaleria = ({clavePaquete, idTipoImagen}) =>{
    const [paqueteGaleria, setPaqueteGaleria] = useState([])
    const [isLoad, setLoad] = useState(false)
    useEffect(() =>{
        setLoad(true)
        Promise.all([getPaqueteGaleria({clavePaquete, idTipoImagen})]).then(Response => {
            setPaqueteGaleria(Response[0]);
        }).catch(Error => {
            setPaqueteGaleria([]);
        }).finally(() => {
            setLoad(false)
        })
        
        return () =>{
            setPaqueteGaleria([]);
        }
    },[clavePaquete, idTipoImagen])
    return [paqueteGaleria, isLoad];
}