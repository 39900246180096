import esLocale from "date-fns/locale/es";
import { format, parseISO, addMonths, subDays } from 'date-fns';
export const FormatFecha = (date) => {
    return format(date, 'yyyy-MM-dd');
}
export const FormatoFecha = ({ date, formato }) => {
    if (!date)
        return null
    return format(parseISO(date), formato, {
        locale: esLocale
    });
}
export const formatAddMonth = ({ date, months, formato }) => {
    if (!date)
        return null
    return format(addMonths(parseISO(date), months), formato, { locale: esLocale })
}

export const formatSubDaysIso = ({ date, days, formato }) => {
    if (!date)
        return null
    return format(subDays(parseISO(date), days), formato, { locale: esLocale })
}