export const formatDinero = (valor) =>{
    return new Intl.NumberFormat("ES-MX", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(valor);   
}

export const formatDineroCentavos = (valor) =>{
  return new Intl.NumberFormat("ES-MX", {
    style: "currency",
    currency: "MXN"
  }).format(valor);   
}