import "./custom.css"
import { animated } from '@react-spring/web'
import styles from "./index.module.css"
import appMovil from "./../../../assets/appMovil.png"
import Carousel from 'react-bootstrap/Carousel'
import { useAnimationElmentoFadeRigth } from '../hooks/useAnimationElmentoFadeRigth'
import { useAnimationElmentoZoomIn } from '../hooks/useAnimationElmentoZoomIn'
const AppReferencia = () => {
    const [refTitulo, propsTitulo] = useAnimationElmentoFadeRigth()
    const [refImagen, propsImagen] = useAnimationElmentoZoomIn()
    const {refCarrucel, propsCarrucel} = useAnimationElmentoZoomIn()
    return (
        <div className={styles.container}>
            <animated.div ref={refTitulo} style={propsTitulo}>
                <h3 className={styles.title}>PORQUE LA APP DE DOODDO ES LA MÁS DESCARGADA ENTRE LOS VIAJEROS</h3>
            </animated.div>
            <animated.div ref={refImagen} style={propsImagen}>
                <img className={styles.iamgen} src={appMovil} alt="App Movil" />
            </animated.div>
            <animated.div ref={refCarrucel} style={propsCarrucel}>
                <Carousel controls={false} indicators={true} interval={null} className="customCarrucelLPMOV">
                    <Carousel.Item>
                        <div className={styles.card}>
                            <h6>Puedes verificar el estatus de tu viaje</h6>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className={styles.card}>
                            <h6>Puedes organizar tu viaje</h6>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className={styles.card}>
                            <h6>Puedes organizar grupos de viaje con familiares y amigos</h6>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className={styles.card}>
                            <h6>Tienes control total de tu viaje</h6>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className={styles.card}>
                            <h6>Referencia única en BBVA</h6>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </animated.div>
        </div>
    )
}
export default AppReferencia