import {useEffect, useState} from "react"
import { getExtrasByPaqueteId } from "../services/extrasServices"
export const useExtras = ({idPaquete}) =>{
    const [extras, setExtras] = useState([]);
    useEffect(() =>{
        Promise.all([getExtrasByPaqueteId({idPaquete})]).then(Response => {
            setExtras(Response[0]);
        }).catch(Error => {
            setExtras([]);
        })
        return () =>{
            setExtras([]);
        }
    },[idPaquete])
    return [extras];
}