import { configureStore, combineReducers, compose, applyMiddleware } from "@reduxjs/toolkit"
import thunk from "redux-thunk";
/**
 * *NOTA: Importan los patos
 */
import sliderReducer from "../duck/sliderDuck"
/**
 * *Nota: Se añade los paratos al reducer
 */
const rootReduce = combineReducers({
    slider: sliderReducer
})

/**
 * *Nota: Se configura pluging para el navegador
 * !Importante: Eliminar "window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__" para productivo
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__|| compose;

/**
 * @store
 */
export default function generateStore() {
    const store = configureStore({reducer: rootReduce, middleware: [thunk], devTools: composeEnhancers});
    return store;
}