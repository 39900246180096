import LogoDoodo from "./../../assets/white_logo.png";
import './index.css';
import { SITE_DOMAIN } from "./../../config/siteConfig"
import { useTipoCambio } from "../../hooks/useTipoCambio";
import { formatDineroCentavos } from "./../../utils/getDinero"
const Header = (props) => {
    const { isTipoCambio = true, isRedirec = true } = props
    const { tipoCambio } = useTipoCambio()
    return (
        <div className="HeaderContainer-white">
            <div className="HeaderLogo-white">
                {
                    (isRedirec) &&
                    <a href={`${SITE_DOMAIN}`} >
                        <img src={LogoDoodo} alt="Dooddo" />
                    </a>
                }
                {
                    (!isRedirec) &&
                    <img src={LogoDoodo} alt="Dooddo" />
                }

            </div>
            <div>
                <h5>Ahorra, viaja y repite</h5>
                {
                    (isTipoCambio) &&
                    <span>T.C. {formatDineroCentavos(tipoCambio.TipoCambio)}</span>
                }
            </div>
        </div>
    )
}
export default Header;