import {useEffect, useState} from "react"
import { getExtrasById } from "../services/extrasServices"
export const useExtraId = ({idExtra}) =>{
    const [extra, setExtra] = useState({});
    useEffect(() =>{
        Promise.all([getExtrasById({idExtra})]).then(Response => {
            setExtra(Response[0]);
        }).catch(Error => {
            setExtra({});
        })
        return () =>{
            setExtra({});
        }
    },[idExtra])
    return [extra];
}