import axios from "axios";
import { APIURL } from "./../config/siteConfig";
import { getCabecera } from "../utils/getCabecera";

const urlAddon = "Addon";

export const getExtrasByPaqueteId = async ({idPaquete}) =>{
  return await axios.get(`${APIURL}/${urlAddon}/Package/${idPaquete}`, {
    headers: getCabecera(),
  }).then((Response) => {
    return Response.data;
  });
};


export const getExtrasById = async ({idExtra}) =>{
  return await axios.get(`${APIURL}/${urlAddon}/${idExtra}`, {
    headers: getCabecera(),
  }).then((Response) => {
    return Response.data;
  });
};