import "./index.css"
import { Carousel } from "react-bootstrap"
import { usePaquetesOfertasLimit } from "../../hooks/usePaqueteOfertasLimit"
import { APIURL } from "../../config/siteConfig"
import ImgenDefault from "./../../assets/paquetes/paquete.jpg"
const CarrucelBanner = () => {
    const [paquetes] = usePaquetesOfertasLimit({ Busqueda: "0" })
    return (
        <div className="Carousel-Banner">
            <Carousel>
                {
                    (paquetes.length === 0) &&
                    <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={ImgenDefault}
                                alt="First slide"
                            />
                            {/* <Carousel.Caption>
                    <h5>HUATULCO ALL INCLUSIVE</h5>
                    </Carousel.Caption> */}
                    </Carousel.Item>
                }
                {
                    (paquetes.length > 0) &&
                    paquetes.map(paquete =>
                        <Carousel.Item key={paquete.IdPaquete}>
                            <img
                                className="d-block w-100"
                                src={`${APIURL}/Trip/Package/${paquete.IdPaquete}/Portada`}
                                alt="First slide"
                            />
                            {/* <Carousel.Caption>
                    <h5>HUATULCO ALL INCLUSIVE</h5>
                    </Carousel.Caption> */}
                        </Carousel.Item>
                    )
                }

            </Carousel>
        </div>
    )
}
export default CarrucelBanner;