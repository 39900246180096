import Contain from "../../global/Contain"
import { useExtraId } from "../../hooks/useExtraId"
import Extras from "../../components/TabDetalle/Extras"
import classes from "./index.module.css"
import { useParams } from "react-router"
export function ExtraDetail() {
    const { id } = useParams()
    const [extra] = useExtraId({ idExtra: id });
    return (
        <div>
            <Contain>
                <div className={classes.main}>
                    <Extras
                        Nombre={extra.Extra?.Nombre}
                        Suplemento={extra?.Suplemento}
                        Descripcion={extra.Extra?.Descripcion}
                        Moneda={extra.ClaveMoneda}
                    />
                    <div>
                        **Precios por pasajero
                    </div>
                </div>
            </Contain>
        </div>
    )
}