import { useEffect, useState } from "react";
import { getMontoMensual } from "../services/paqueteService";
import { catTipoPago } from "../constant/cattipoPago";
import { formatAddMonth } from "../utils/getFecha";
export const useMensualidades = ({ fechaSalida, Monto, fechaRegistro, listaPagos = [] }) => {
    const [mesualidades, setMensualidades] = useState([]);
    const [load, setLoad] = useState(true)
    const pagoTipo = listaPagos.find(pago => pago.CatTipoPago.IdCatTipoPago === Number(catTipoPago.PLAN_PAGOS))
    useEffect(() => {
        const calcularMensualidad = () => {
            let fecha = fechaSalida
            let monto = Monto
            if (pagoTipo.PorcentajePago !== 100) {
                monto = (pagoTipo?.PorcentajePago * Monto) / 100
                fecha = formatAddMonth({ date: fechaRegistro, formato: "MMddyyyy", months: pagoTipo.NumeroPagos })
            }
            setLoad(true)
            Promise.all([getMontoMensual({ FechaViaje: fecha, MontoPaquete: monto })]).then(Response => {
                setLoad(false)
                setMensualidades(Response[0])
            }).catch(Error => {
                setLoad(false)
                setMensualidades([])
            })
        }
        if (pagoTipo)
            calcularMensualidad()
        return () => {
            setMensualidades([]);
        }

    }, [fechaSalida, Monto, pagoTipo, fechaRegistro])
    return [mesualidades, load];
}