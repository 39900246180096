import { animated } from '@react-spring/web'
import styles from "./index.module.css"
import { useAnimationElmentoFadeRigth } from '../hooks/useAnimationElmentoFadeRigth'
import { useAnimationElmentoZoomIn } from '../hooks/useAnimationElmentoZoomIn'
const Preguntas = () => {
    const [tituloRef, tituloAnimation] = useAnimationElmentoFadeRigth()
    const [texto1, texto1Animation] = useAnimationElmentoZoomIn()
    const [texto2, texto2Animation] = useAnimationElmentoZoomIn()
    const [texto3, texto3Animation] = useAnimationElmentoZoomIn()
    const [texto4, texto4Animation] = useAnimationElmentoZoomIn()
    const [texto5, texto5Animation] = useAnimationElmentoZoomIn()
    const [texto6, texto6Animation] = useAnimationElmentoZoomIn()
    const [texto7, texto7Animation] = useAnimationElmentoZoomIn()
    return (
        <div className={styles.container}>
            <animated.div ref={tituloRef} style={tituloAnimation}>
                <h3 className={styles.title}>Preguntas frecuentes</h3>
            </animated.div>
            <animated.div ref={texto1} stylce={texto1Animation}>
                <div className={styles.box}>
                    <p className={styles.subTitle}>¿Desde cuándo lleva operando Dooddo?</p>
                    <p>Dooddo comenzó sus operaciones desde 2016.</p>
                </div>
            </animated.div>
            <animated.div ref={texto2} style={texto2Animation}>
                <div className={styles.box}>
                    <p className={styles.subTitle}>¿Por qué contratar Dooddo?</p>
                    <p>Porque hay atención 100% personalizada con agentes de venta capacitados.</p>
                </div>
            </animated.div>
            <animated.div ref={texto3} style={texto3Animation}>
                <div className={styles.box}>
                    <p className={styles.subTitle}>¿Dooddo es real?,¿Dónde están ubicados?</p>
                    <p>Nos encontramos ubicados en:  <br />Paseo Polanco,
                        Lago Alberto 367, Anáhuac I Secc, Miguel
                        Hidalgo, 11320 <br /> Ciudad de México, CDMX. Piso 1</p>
                    <p>WhatsApp: +52 1 56 1865 3302</p>
                    <p>Oficinas Dooddo: +52 55 9414 7536</p>
                </div>
            </animated.div>
            <animated.div ref={texto4} style={texto4Animation}>
                <div className={styles.box}>
                    <p className={styles.subTitle}>¿Qué incluyen nuestros paquetes?</p>
                    <p>Vuelo redondo, alojamiento, guías de habla
                        hispana, traslados, equipaje.</p>
                </div>
            </animated.div>
            <animated.div ref={texto5} style={texto5Animation}>
                <div className={styles.box}>
                    <p className={styles.subTitle}>¿Cuales son los planes de pago?</p>
                    <p>Contamos con 3 planes de pago: <br />
                        1.- Plan de ahorro <br />
                        2.- Mensualidades con tarjeta de crédito <br />
                        3.- Plan híbrido <br />
                        *Consúltalo con tu asesor por cualquier duda.</p>
                </div>
            </animated.div>
            <animated.div ref={texto6} style={texto6Animation}>
                <div className={styles.box}>
                    <p className={styles.subTitle}>¿Dónde puedo encontrar la AppDooddo?</p>
                    <p>La encuentras en AppStore (Ios)
                        y Play Store (Android).</p>
                </div>
            </animated.div>
            <animated.div ref={texto7} style={texto7Animation}>
                <div className={styles.box}>
                    <p className={styles.subTitle}>¿Qué se necesita para usar la AppDooddo?</p>
                    <p>Contar previamente con un registro
                        interno, es decir, tener ya un paquete
                        con nosotros.</p>
                </div>
            </animated.div>
        </div>
    )
}
export default Preguntas