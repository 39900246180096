import "./index.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
const Title = (props) => {
    AOS.init();
    const { titulo = "", isWhite = false } = props
    return (
        <div className={isWhite ? "main-titulo white" : "main-titulo"} data-aos='fade-down'>
            <h1 >{titulo}</h1>
        </div>
    )
}
export default Title;