import "./custom.css"
import styles from "./index.module.css"
import { targetClavePaqueteAction, targetPaqueteAction } from "../../../duck/sliderDuck"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Carousel from 'react-bootstrap/Carousel'
import { APIURL } from '../../../config/siteConfig'
import { animated } from "@react-spring/web"
import { useAnimationElmentoZoomIn } from "../hooks/useAnimationElmentoZoomIn"
const Paquete = () => {
    const [ref, props] = useAnimationElmentoZoomIn()
    const { paquetesLanding } = useSelector(store => store.slider)
    const paquetes = paquetesLanding
    const dispatch = useDispatch()
    const handleOnClik = ({ paquete }) => {
        dispatch(targetClavePaqueteAction({ clavePaquete: paquete?.ClavePaquete, titulo: paquete?.Nombre, idPaquete: paquete?.IdPaquete }))
        dispatch(targetPaqueteAction({ paquete: paquete }))
    }
    return (
        <animated.div ref={ref} style={props}>
            <Carousel controls={false} indicators={true} interval={null} className="customCarrucelLPPAQ">
                {
                    paquetes.map((paquete, index) =>
                        <Carousel.Item key={index + 1}>
                            <div className={styles.paqueteContainer}>
                                <img
                                    style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                                    src={`${APIURL}/Trip/Package/${paquete?.ClavePaquete}/Landing`}
                                    alt={paquete?.Nombre}
                                    onClick={() => handleOnClik({ paquete })}
                                />
                            </div>
                        </Carousel.Item>
                    )
                }
            </Carousel>
        </animated.div>
    )
}
export default Paquete