import {useEffect, useState} from "react";
import { getPaqueteDestinosPais } from "../services/paqueteService";
export const useCiudades = ({idPaquete}) =>{
    const [destinos, setCiudades] = useState([]);
    useEffect(() =>{
        Promise.all([getPaqueteDestinosPais({idPaquete})]).then(Response => {
            setCiudades(Response[0]);
        }).catch(Error => {
            setCiudades([]);
        })
        
        return () =>{
            setCiudades([]);
        }
    },[idPaquete])
    return [destinos];
}