import "./index.css";
import Contain from "../../global/Contain";
import Title from "../../global/Title";
import NosotrosOne from "./../../assets/hexaDooddo.webp"
import NosotrosDos from "./../../assets/porqueDooddo.png"
import NosotrosLan from "./../../assets/nosotrosLan.jpg"
import Avion from "./../../assets/icon/avion.png"
import Corazon from "./../../assets/icon/corazon.png"
import Maleta from "./../../assets/icon/maleta.png"
import Header from "../../global/Header"
import Footer from "../../global/Footer"
import AOS from 'aos';
import 'aos/dist/aos.css';
const About = () => {
    AOS.init();
    return (
        <div>
            <Header/>
            <Contain>
            <Title titulo="Nosotros" />
            <div className="About-main">
                <div className="About-grid">
                    <div className="About-one" data-aos='fade-up'>
                        <img src={NosotrosOne} alt="Viaje" />
                    </div>
                    <div className="About-two" data-aos='fade-right'>
                        <p>Dooddo® orgullosos de ser una empresa 100% mexicana, es la primera plataforma fintech en el país dedicada exclusivamente al turismo, que le ofrece a los usuarios una experiencia distinta para poder viajar.</p>
                    </div>
                    <div className="About-three" data-aos='fade-left'>
                        <p>Mediante la realización de un plan de pagos, le ofrecemos a cualquier persona la oportunidad de viajar pagando su viaje en mensualidades a través de nuestra plataforma digital obteniendo como beneficios:</p>
                        <h4>MEJORES COSTOS</h4>
                        <p>Al ser viajes a largo plazo obtienes mejores precios en vuelos, hospedajes, tours y actividades</p>
                        <h4>OMISIÓN DE INTERESES</h4>
                        <p>Contamos con diversas formas de pago, todas te permiten realizar tus aportaciones sin intereses o cominisiones bancarias.</p>
                    </div>
                    <div className="About-four" data-aos='fade-up'>
                        <img src={NosotrosDos} alt="Viaje" />
                    </div>
                    <div className="About-five" data-aos='fade-up'>
                        <img src={NosotrosLan} alt="Viaje" />
                    </div>
                    <div className="About-six" data-aos='fade-right'>
                        <h4>¿POR QUÉ NOSOTROS?</h4>
                        <p>Nuestra misión en facilitar el proceso de planeación y encargarnos de la logística del viaje mientras tú realizas aportaciones mensuales de acuerdo a tu presupuesto y expectativas de viaje.</p>
                    </div>
                    <div className="About-seven">
                        <div className="Avout-seven-item">
                            <img src={Avion} alt="Avion" data-aos='fade'/>
                            <h4>PROPÓSITO</h4>
                            <p>Cambiar la forma de viajar y demostrar que viajar es muy fácil, brindamos a cualquier persona la posibilidad de viajar a través de facilidades de pago, precios competitivos, soluciones tecnológicas y diversidad de destinos.</p>
                        </div>
                        <div className="Avout-seven-item">
                            <img src={Maleta} alt="Maleta" data-aos='fade' />
                            <h4>VISIÓN</h4>
                            <p>Expandirnuestra cultura y tecnología a través del mundo siempre siendo una empresa socialmente responsable, mientras somos reconocidos como la empresa mexicana más innovadora en el sector turístico.</p>
                        </div>
                        <div className="Avout-seven-item">
                            <img src={Corazon} alt="Corazon" data-aos='fade'/>
                            <h4>VALORES</h4>
                            <p>Transmitimos la PASIÓN por viajar a cada cliente, es el motor de la empresa y la generadora de la unión. el RESPETO, la CONFIANZA, la HONESTIDAD y la LEALTAD se imponen como pilar fundamental en todas nuestras relaciones.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Contain>
        <Footer/>
        </div>
    )
}
export default About;