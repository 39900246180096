import React from 'react';
import { useSpring, animated } from '@react-spring/web';

const AnimatedSVG = () => {
    const pathAnimation = useSpring({
        from: { stroke: '#1D8ECE', strokeWidth: 1 },
        to: async (next) => {
            while (true) {
                await next({ stroke: '#FFFFFF', strokeWidth: 3 });
                await next({ stroke: '#1D8ECE', strokeWidth: 1 });
            }
        },
        config: { duration: 1000 },
    });

    const planeAnimation = useSpring({
        from: { transform: 'translate(0px, 0px) rotate(0deg)' },
        to: async (next) => {
            while (true) {
                await next({ transform: 'translate(50px, -50px) rotate(10deg)' });
                await next({ transform: 'translate(0px, 0px) rotate(0deg)' });
            }
        },
        config: { duration: 2000 },
    });

    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 525.28 1080"
            style={{ enableBackground: 'new 0 0 525.28 1080' }}
            xmlSpace="preserve"
        >
            <style type="text/css">
                {`
         .st0{opacity:0.48;}
	.st1{fill:#FFFFFF;}
	.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
	.st3{fill:#1D8ECE;stroke:#1D8ECE;stroke-width:0.25;stroke-miterlimit:10;}
	.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#1D8ECE;stroke:#1D8ECE;stroke-width:0.25;stroke-miterlimit:10;}
	.st5{opacity:0.5;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
	.st6{opacity:0.5;}
	.st7{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-linejoin:round;}
	.st8{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:17.783,17.783;}
        `}
            </style>
            <g>
                <animated.path
                    className="st3"
                    style={planeAnimation}
                    d="M337.39,49.63C319.9,35.59,278.76,9.38,278.76,9.38c3.95-4.97,6.76-9.19,16.16-6.25l22.54,9.5
			c0,0,9-5.2,11.68-6.74c1.86-1.08,4.76-0.78,6.25,1.8c1.49,2.58-0.04,5.13-1.89,6.19c-3.49,2.02-5.88,3.46-5.88,3.46l23.09,9.49
			c0,0,10.7-6.13,11.69-6.7c1.56-0.9,4.71-0.97,6.23,1.67c1.53,2.65-0.01,5.27-1.67,6.23c-1.2,0.69-5.78,3.34-5.78,3.34l30.82,13.59
			c0,0,28.92-16.93,43.12-23.19c5.28-2.34,8.79,0.43,10.09,2.64l0,0c0,0.01,0.01,0.02,0.01,0.02c0,0.01,0.01,0.02,0.02,0.02l0,0
			c1.26,2.23,1.91,6.66-2.76,10.06c-12.52,9.16-41.64,25.74-41.64,25.74l-3.64,33.48c0,0,4.58-2.64,5.78-3.34
			c1.66-0.96,4.71-0.97,6.23,1.67c1.53,2.65-0.11,5.33-1.67,6.23c-0.98,0.57-11.65,6.77-11.65,6.77l-3.33,24.74
			c0,0,2.44-1.34,5.94-3.36c1.84-1.06,4.81-1.12,6.3,1.46c1.49,2.58,0.29,5.23-1.57,6.31c-2.68,1.55-11.68,6.74-11.68,6.74
			l-3.05,24.27c-2.15,9.61-7.22,9.93-13.49,10.87c0,0-2.13-48.73-5.54-70.9c-1.95-12.61-15.8-19.88-28.01-12.83
			c-12.41,7.17-23.91,12.83-36.48,19.71c-1.67,11.07-3.33,22.15-5.02,33.22c-3.28,2.95-6.57,5.88-9.85,8.81
			c-2.81-10.86-5.63-21.72-8.44-32.59c-2.77,0.73-8.53,2.24-8.53,2.24c2-2.06,4.19-4.23,6.21-6.27
			c-8.01-7.87-16.01-15.74-24.01-23.61c4.18-1.38,8.36-2.75,12.56-4.12c10.44,4.07,20.85,8.17,31.28,12.26
			c12.24-7.45,22.89-14.58,35.31-21.74C346.7,73.25,347.34,57.62,337.39,49.63z"
                />
                <g className="st6">
                    <g>
                        <animated.path
                            className="st3"
                            style={pathAnimation}
                            d="M493.48,1072.67l0.22-4.48c-7.39-0.37-14.81-0.89-22.05-1.57l-0.41,4.47
					C478.55,1071.77,486.03,1072.3,493.48,1072.67z M457.93,1069.69l0.53-4.45c-7.28-0.87-14.64-1.92-21.88-3.1l-0.73,4.43
					C443.16,1067.76,450.59,1068.81,457.93,1069.69z M422.69,1064.22l0.84-4.41c-7.17-1.37-14.44-2.92-21.61-4.62l-1.04,4.37
					C408.12,1061.27,415.45,1062.84,422.69,1064.22z M387.91,1056.29l1.15-4.34c-7.05-1.87-14.19-3.94-21.23-6.14l-1.35,4.29
					C373.59,1052.31,380.8,1054.4,387.91,1056.29z M353.78,1045.91l1.46-4.25c-6.95-2.38-13.93-4.95-20.74-7.63l-1.64,4.18
					C339.73,1040.91,346.77,1043.5,353.78,1045.91z M320.51,1033.16l1.75-4.13c-6.71-2.85-13.48-5.89-20.11-9.05l-1.93,4.05
					C306.91,1027.22,313.74,1030.29,320.51,1033.16z M288.25,1018.13l2.04-4c-6.51-3.32-13.05-6.83-19.43-10.45l-2.21,3.91
					C275.09,1011.24,281.69,1014.78,288.25,1018.13z M257.11,1000.85l2.31-3.84c-6.27-3.77-12.55-7.74-18.66-11.79l-2.48,3.74
					C244.45,993.04,250.78,997.04,257.11,1000.85z M227.25,981.42l2.58-3.67c-5.98-4.19-11.97-8.59-17.8-13.07l-2.74,3.56
					C215.17,972.76,221.22,977.2,227.25,981.42z M198.81,959.95l2.83-3.49c-5.69-4.62-11.36-9.43-16.85-14.29l-2.98,3.36
					C187.34,950.44,193.06,955.29,198.81,959.95z M171.91,936.53l3.06-3.28c-5.38-5.02-10.7-10.22-15.82-15.44l-3.2,3.14
					C161.12,926.22,166.49,931.46,171.91,936.53z M146.71,911.28l3.29-3.06c-4.99-5.36-9.94-10.91-14.71-16.51l-3.41,2.92
					C136.68,900.27,141.68,905.87,146.71,911.28z M123.32,884.34l3.49-2.82c-4.61-5.71-9.16-11.59-13.52-17.5l-3.61,2.67
					C114.08,872.64,118.67,878.58,123.32,884.34z M101.86,855.81l3.68-2.57c-4.22-6.04-8.35-12.23-12.27-18.41l-3.79,2.41
					C93.44,843.46,97.61,849.71,101.86,855.81z M82.45,825.83l3.85-2.31c-3.77-6.29-7.45-12.76-10.95-19.23l-3.95,2.13
					C74.93,812.96,78.65,819.48,82.45,825.83z M65.19,794.55l4-2.03c-3.32-6.55-6.54-13.26-9.57-19.96l-4.09,1.85
					C58.59,781.17,61.84,787.95,65.19,794.55z M50.15,762.12l4.14-1.74c-2.85-6.78-5.59-13.71-8.14-20.6l-4.21,1.56
					C44.51,748.29,47.28,755.28,50.15,762.12z M37.46,728.69l4.25-1.45c-2.36-6.94-4.6-14.03-6.65-21.07l-4.31,1.25
					C32.82,714.52,35.07,721.68,37.46,728.69z M27.18,694.53l4.34-1.14c-1.87-7.11-3.59-14.34-5.14-21.49l-4.39,0.95
					C23.55,680.06,25.3,687.35,27.18,694.53z M19.34,659.72l4.41-0.83c-1.35-7.19-2.57-14.52-3.61-21.8l-4.45,0.64
					C16.75,645.07,17.98,652.47,19.34,659.72z M13.98,624.46l4.46-0.52c-0.84-7.25-1.54-14.65-2.08-22l-4.48,0.32
					C12.42,609.68,13.12,617.14,13.98,624.46z M11.09,588.91l4.48-0.21c-0.34-7.27-0.52-14.71-0.55-22.09l-4.49,0.01
					C10.57,574.07,10.75,581.57,11.09,588.91z M15.17,553.34c0.17-7.32,0.5-14.74,0.98-22.08l-4.48-0.3
					c-0.49,7.4-0.82,14.89-0.99,22.28L15.17,553.34z M17.2,518.04c0.68-7.32,1.53-14.71,2.52-21.96l-4.45-0.61
					c-1,7.31-1.85,14.77-2.54,22.15L17.2,518.04z M21.69,482.97c1.18-7.22,2.54-14.53,4.05-21.72l-4.39-0.92
					c-1.52,7.26-2.9,14.64-4.09,21.92L21.69,482.97z M28.63,448.3c1.69-7.12,3.56-14.3,5.57-21.35l-4.32-1.23
					c-2.02,7.1-3.91,14.35-5.62,21.54L28.63,448.3z M37.99,414.28c2.2-7.01,4.58-14.05,7.06-20.91l-4.22-1.53
					c-2.51,6.92-4.91,14.02-7.13,21.1L37.99,414.28z M49.73,380.99c2.69-6.83,5.55-13.69,8.52-20.36l-4.1-1.82
					c-3,6.74-5.89,13.65-8.6,20.55L49.73,380.99z M63.79,348.59c3.15-6.6,6.49-13.23,9.94-19.72l-3.96-2.11
					c-3.48,6.55-6.85,13.24-10.03,19.9L63.79,348.59z M80.09,317.26c3.61-6.38,7.41-12.76,11.3-18.98l-3.81-2.38
					c-3.93,6.27-7.76,12.72-11.4,19.15L80.09,317.26z M98.57,287.14c4.04-6.1,8.28-12.21,12.6-18.15l-3.63-2.64
					c-4.36,5.99-8.64,12.15-12.72,18.3L98.57,287.14z M119.12,258.38c4.46-5.8,9.11-11.6,13.84-17.22l-3.44-2.89
					c-4.77,5.68-9.47,11.53-13.97,17.38L119.12,258.38z M141.62,231.12c4.87-5.5,9.92-10.95,15.02-16.22l-3.23-3.12
					c-5.14,5.31-10.23,10.82-15.15,16.36L141.62,231.12z M165.99,205.49c5.23-5.13,10.65-10.22,16.11-15.13l-3-3.34
					c-5.51,4.96-10.98,10.09-16.26,15.27L165.99,205.49z M192.08,181.62c5.56-4.74,11.32-9.44,17.13-13.98l-2.76-3.54
					c-5.86,4.57-11.68,9.32-17.29,14.1L192.08,181.62z M219.78,159.62c5.91-4.36,11.99-8.65,18.07-12.75l-2.51-3.72
					c-6.14,4.13-12.27,8.46-18.23,12.86L219.78,159.62z M248.96,139.6c6.21-3.95,12.57-7.8,18.92-11.45l-2.24-3.89
					c-6.4,3.69-12.83,7.57-19.09,11.55L248.96,139.6z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

const AvionAzul = () => {
    return (
        <div className="App">
            <AnimatedSVG />
        </div>
    );
};

export default AvionAzul;
