import React from 'react';
import { useSpring, animated } from '@react-spring/web';

const AnimatedSVG = () => {
  const pathAnimation = useSpring({
    from: { stroke: '#1D8ECE', strokeWidth: 1 },
    to: async (next) => {
      while (true) {
        await next({ stroke: '#FFFFFF', strokeWidth: 3 });
        await next({ stroke: '#1D8ECE', strokeWidth: 1 });
      }
    },
    config: { duration: 1000 },
  });

  const planeAnimation = useSpring({
    from: { transform: 'translate(0px, 0px) rotate(0deg)' },
    to: async (next) => {
      while (true) {
        await next({ transform: 'translate(50px, -50px) rotate(10deg)' });
        await next({ transform: 'translate(0px, 0px) rotate(0deg)' });
      }
    },
    config: { duration: 2000 },
  });

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 757.74 608.62"
      style={{ enableBackground: 'new 0 0 757.74 608.62' }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`
          .st0{opacity:0.48;}
          .st1{fill:#FFFFFF;}
          .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
          .st3{fill:#1D8ECE;stroke:#1D8ECE;stroke-width:0.25;stroke-miterlimit:10;}
          .st4{fill-rule:evenodd;clip-rule:evenodd;fill:#1D8ECE;stroke:#1D8ECE;stroke-width:0.25;stroke-miterlimit:10;}
          .st5{opacity:0.5;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
          .st6{opacity:0.5;}
          .st7{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-linejoin:round;}
          .st8{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:17.783,17.783;}
        `}
      </style>
      <g>
        <animated.path
          className="st5"
          style={planeAnimation}
          d="M704.65,78.28c-1.8,11.2-8.35,35.01-8.35,35.01c3.17,0.52,5.66,1.16,8.19-3.14l5.24-11.22
            c0,0,5.14-1.1,6.67-1.43c1.06-0.23,2.05-1.32,1.74-2.8c-0.32-1.47-1.76-1.91-2.81-1.68c-2,0.43-3.38,0.69-3.38,0.69l5.45-11.4
            c0,0,6.1-1.33,6.66-1.45c0.89-0.19,2.1-1.23,1.77-2.74c-0.32-1.51-1.79-1.98-2.74-1.77c-0.69,0.15-3.3,0.71-3.3,0.71l6.96-15.56
            c0,0,16.6-3.46,24.06-5.93c2.78-0.91,3.15-3.14,2.89-4.42l0,0c0,0,0-0.01,0-0.01c0,0,0-0.01,0-0.01l0,0
            c-0.28-1.27-1.55-3.15-4.45-2.84c-7.81,0.81-24.37,4.47-24.37,4.47l-12.73-11.33c0,0,2.62-0.56,3.3-0.71
            c0.95-0.2,2.1-1.23,1.77-2.74s-1.85-1.96-2.74-1.77c-0.56,0.12-6.67,1.41-6.67,1.41l-9.64-8.16c0,0,1.37-0.33,3.37-0.76
            c1.05-0.23,2.19-1.21,1.87-2.69s-1.67-2.06-2.73-1.84c-1.53,0.33-6.67,1.43-6.67,1.43l-9.38-8.08c-4.07-2.88-6.08-1.28-8.75,0.5
            c0,0,15.74,19.02,21.98,28.49c3.55,5.4,0.82,12.82-6.16,14.32c-7.09,1.52-13.33,3.3-20.38,4.98c-4.39-3.59-8.77-7.18-13.16-10.77
            c-2.23,0.01-4.46,0.02-6.69,0.03c2.63,5.03,5.26,10.07,7.89,15.1c-1.29,0.67-3.96,2.05-3.96,2.05c1.45,0.09,3.01,0.17,4.46,0.24
            c-0.34,5.67-0.66,11.34-1,17.01c2.04-0.9,4.07-1.8,6.11-2.71c2.53-5.07,5.05-10.15,7.58-15.22c7.12-1.36,13.54-2.3,20.63-3.82
            C700.12,66.25,705.67,71.91,704.65,78.28z"
        />
        <g className="st6">
          <g>
            <animated.path
              className="st7"
              style={pathAnimation}
              d="M0,574.43c2.94,0.61,5.88,1.24,8.8,1.88"
            />
            <animated.path
              className="st8"
              style={pathAnimation}
              d="M26.14,580.26c13.78,3.21,27.43,6.49,41.04,9.26c123.77,25.12,363.06-26.51,363.06-249.12
                c0-182.18,134.63-233.86,183.52-246.62"
            />
            <animated.path
              className="st7"
              style={pathAnimation}
              d="M622.41,91.7c5.62-1.23,8.84-1.67,8.84-1.67"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const Avion = () => {
  return (
    <div className="App">
      <AnimatedSVG />
    </div>
  );
};

export default Avion;
