import axios from "axios"
import { APIURL } from "../config/siteConfig"
import { getCabecera } from "../utils/getCabecera"

const urlLanding = "Landing"

//* Obtener tosdas las landing habilitadas
//* GET /api/Landing/Available
export const getLandingAllHabilitadas = async () => {
    return axios.get(`${APIURL}/${urlLanding}/Available`,
        {
            headers: getCabecera()
        }).then((Response) => {
            return Response.data
        }).catch((Error) => {
            //return Error.response.data;
            return []
        })
}