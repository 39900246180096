import {useEffect, useState} from "react"
import { getLandingAllHabilitadas } from "../services/landingServiece"
export const useLandingsAll = () =>{
    const [landings, setLadings] = useState([])
    const [load, setLoad] = useState(true)
    useEffect(() =>{
        setLoad(true)
        Promise.all([getLandingAllHabilitadas()]).then(Response => {
            setLadings(Response[0]);
        }).catch(Error => {
            setLadings([])
        }).finally(() => {
            setLoad(false)
        })
        return () =>{
            setLadings([])
            setLoad(false)
        }
    },[])
    return [landings, load]
}