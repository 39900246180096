import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { SITE_DOMAIN } from "../../config/siteConfig";

const INITALIMAGES = [
    {
        src: `${SITE_DOMAIN}mapa.png`,
        alt: "image 4",
    },
    {
        src: `${SITE_DOMAIN}Galeria1.png`,
        alt: "image 1",
    },
    {
        src: `${SITE_DOMAIN}Galeria2.png`,
        alt: "image 2",
    },
    {
        src: `${SITE_DOMAIN}Galeria3.png`,
        alt: "image 3",
    },
]
const GaleriaPaquete = (props) => {
    const { galeria = [], children } = props
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <button type="button" onClick={() => setOpen(true)}>
                {
                    children
                }
            </button>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={(galeria.length > 0) ? galeria : INITALIMAGES}
            />
        </>
    );
}
export default GaleriaPaquete