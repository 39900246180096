import "./index.css"
import Contain from "../../global/Contain"
import SubTitle from "../../global/SubTitle"
import Header from "./../../global/Header"
import PaqueteCard from "../../components/PauqeteCard"
import DestinosMenu from "../../components/DestinosMenu"
import { usePaquetesOfertas } from "../../hooks/usePaqueteOfertas"
import { APIURL } from "../../config/siteConfig"
import Ofertas from "./../../assets/destinos/promo.png"
import Footer from "../../global/Footer"

const PaquetesOferta = () => {
    const [paquetes] = usePaquetesOfertas({Busqueda: "0"})
    return (
        <div>
            <Header />
            <Contain>
                <div className="Paquetes-Oferta-main-content">
                    <div className="Paquete-Oferta-main-content-flex">
                        <img src={Ofertas} alt="Logo" />
                        <SubTitle titulo="Ofertas" />
                    </div>
                    <div className="Paquete-Oferta-main-content-flex">
                        <DestinosMenu/>
                    </div>
                </div>
                <div className="Paquetes-Oferta-main">
                    {
                        paquetes.map((paquete) => 
                            <PaqueteCard 
                                key={paquete.IdPaquete}
                                id={paquete.IdPaquete}
                                folio={paquete.FolioPaquete}
                                src={`${APIURL}/Trip/Package/${paquete.IdPaquete}/Portada`}
                                nombre={paquete.Nombre}
                                dias={paquete.Dias}
                                noches={paquete.Noches}
                                precio={paquete.PrecioPublico}
                                moneda={(paquete.Moneda) ? paquete.Moneda.Clave : ""}
                            />
                        )
                    }

                </div>
            </Contain>
            <Footer/>
        </div>
    )
}
export default PaquetesOferta