import { useRef } from "react"
import styles from "./index.module.css"
import { useSelector } from "react-redux"
import WhatsappButton from "../WhatsappButton"
import { useScrollBarProgress } from "../hooks/useScrollBarProgress"
import { useSalidas } from "../../../hooks/useSalidas"
import { obtenerFechasFormateadas } from "../../../utils/formatoFechas"
const Fechas = () => {
    const { idPaquete } = useSelector(store => store.slider)
    const [salidas, load] = useSalidas({ idPaquete: idPaquete })
    const containerRef = useRef(null)
    const [scrollProgress] = useScrollBarProgress({ containerRef: containerRef })
    return (
        <div>
            <div className={styles.progressContainer}>
                <div className={styles.progressBar} style={{ height: `${scrollProgress}%` }}></div>
            </div>
            <div className={styles.scrollContent} ref={containerRef}>
                {
                    (load) ? <div className={styles.fechas}>Cargando...</div>
                        : <div className={styles.fechas}>
                            {
                                obtenerFechasFormateadas(salidas).map((fechasPorAnio, anioIndex) => {
                                    return (
                                        <div key={anioIndex}>
                                            {Object.keys(fechasPorAnio).map((anio, index) => (
                                                <div key={index}>
                                                    <h3 className={styles.titulo}>{anio}</h3>
                                                    {fechasPorAnio[anio].map((fecha, fechaIndex) => (
                                                        <p key={fechaIndex}>{fecha}</p>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })
                            }
                        </div>
                }
            </div>
            <WhatsappButton />
        </div>
    )
}
export default Fechas