import { useEffect, useState } from "react"
export const useScrollBarProgress = ({ containerRef }) => {
    const [scrollProgress, setScrollProgress] = useState(0)
    useEffect(() => {
        const container = containerRef?.current
        const handleScroll = () => {
            const totalHeight = container.scrollHeight - container.clientHeight
            const scrollPosition = container.scrollTop
            const scrollProgress = (scrollPosition / totalHeight) * 100
            setScrollProgress(scrollProgress)
        }

        container?.addEventListener('scroll', handleScroll)
        return () => {
            container?.removeEventListener('scroll', handleScroll)
        }
    }, [containerRef.current])

    return [scrollProgress]
}