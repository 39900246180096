import { portalId, formGuid, UrlFormHubspot } from "../config/siteConfig"
import axios from "axios"
export const newSumitFormulario = async ({ email, firstname, lastname, apellido_materno, phone, paqueteName }) => {
    return await axios.post(`${UrlFormHubspot}${portalId}/${formGuid}`,
        {
            portalId,
            formGuid,
            fields: [
                {
                    name: 'firstname',
                    value: firstname,
                },
                {
                    name: 'lastname',
                    value: lastname
                },
                {
                    name: 'apellido_materno',
                    value: apellido_materno
                },
                {
                    name: 'phone',
                    value: phone,
                },
                {
                    name: 'email',
                    value: email,
                },
                {
                    name: 'paquete',
                    value: paqueteName
                }
            ],
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    ).then((Response) =>{
        return Response
    }).catch((Error) => {
        return Error.response
    });
}