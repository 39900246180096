import axios from "axios";
import { APIURL } from "./../config/siteConfig";
import { getCabecera } from "../utils/getCabecera";
import { catTipoCambio } from "../config/catTipoCambio";
const urlCatalog = "Catalog";

export const getTipoCambio = async () =>{
  return await axios.get(`${APIURL}/${urlCatalog}/TipoCambio/${catTipoCambio.DOLARES}`, {
    headers: getCabecera(),
  }).then((Response) => {
    return Response.data;
  });
};
