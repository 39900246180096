import "./index.css"
import { useState } from "react"
import { Modal, Form, InputGroup } from "react-bootstrap"
import { newSumitFormulario } from "../../services/hubspotServices"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePaqueteClavePaquete } from "../../hooks/usePaqueteClave"
import {CatLada} from "../../utils/CatLada"
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const schema = yup.object({
  firstName: yup.string().required("Este campo es obligatorio"),
  lastName: yup.string().required("Este campo es obligatorio"),
  phone: yup.string().matches(phoneRegExp, 'Número de teléfono no valido').required("Este campo es obligatorio").max(10, "Máximo 10 digitos"),
  email: yup.string().email().required("Este campo es obligatorio"),
}).required();

const DefaultValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: ""
}

const FormularioModal = () => {
  const location = useLocation()
  const { clavePaquete } = useParams()
  const [paquete] = usePaqueteClavePaquete({ ClavePaquete: (location?.state?.clavePaquete) ? location?.state?.clavePaquete : clavePaquete  })
  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  const [success, setSuccess] = useState(false)
  const [lada, setLada] = useState("+52")

  const handleClose = () => {
    onClear()
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const { register, handleSubmit, resetField, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DefaultValues
  });

  const onEnviar = async (data) => {
    const result = await newSumitFormulario({
      firstname: data.firstName,
      lastname: data.lastName,
      apellido_materno: '',
      phone: `${lada}${data.phone}`,
      email: data.email,
      paqueteName: paquete?.Nombre
    });

    if (result.status === 200)
      onClearFiel()
  }
  const onClear = () => {
    resetField("firstName")
    resetField("lastName")
    resetField("phone")
    resetField("email")
    setSuccess(false)
  }
  const onClearFiel = () => {
    resetField("firstName")
    resetField("lastName")
    resetField("phone")
    resetField("email")
    setSuccess(true)
  }

  const onAceptar = () => {
    onClear()
    let path = `/conocemas`;
    navigate(path);
  }

  const hangleChange = (newValue) => {
    setLada(newValue.target.value)
  }

  const modalSuccess = () => {
    return (
      <div>
        <Modal.Body>
          <div className="modal-success-main-text">
            <h2>¡Muchas gracias!</h2>
            <p>Uno de nuestroa agentes de viaje se comonunicará en menos 24 hrs</p>
            <h4>Mientras tanto, te invitamos leer más sobre nosotros</h4>
            <ul>
              <li>Como iniciar mi plan de pagos</li>
              <li>Comentarios de clientes</li>
              <li>Lo que dicen los medios</li>
              <li>y más sobre nosotros</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="modalbton" onClick={onAceptar}>
            Leer mas
          </button>
        </Modal.Footer>
      </div>
    )
  }
  const formulario = () => {
    return (
      <Form onSubmit={handleSubmit(onEnviar)}>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.Nombre">
            <Form.Control type="text" placeholder="*Nombre(s)"  {...register("firstName")} />
            <p>{errors.firstName?.message}</p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.lastName">
            <Form.Control type="text" placeholder="*Apellidos"  {...register("lastName")} />
            <p>{errors.lastName?.message}</p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.Telefono">
            <InputGroup size="sm">
              <Form.Select value={lada} onChange={hangleChange} >
                {
                  CatLada.map(cLada => 
                    <option key={cLada.lada} value={cLada.lada}>{`${cLada.lada} ${cLada.name}`}</option>
                    )
                }

              </Form.Select>
              <Form.Control type="tel" placeholder="*Telefono"  {...register("phone")} />
            </InputGroup>
            <p>{errors.phone?.message}</p>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.email">
            <Form.Control type="email" placeholder="*Correo electronico"  {...register("email")} />
            <p>{errors.email?.message}</p>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <button className="modalbton" type="submit">
            Enviar
          </button>

        </Modal.Footer>
      </Form>
    )
  }
  return (
    <div className="ModalPrimaryForm">
      <button className="ModalPrimaryForm-button" onClick={handleShow}>
        Clic Para CONTRATAR!!
      </button>
      <Modal show={show} onHide={handleClose} centered className="ModalPrimaryFomr-Modal">
        <Modal.Header closeButton>
          <Modal.Title>Mas información</Modal.Title>
        </Modal.Header>
        {
          (success) &&
          modalSuccess()
        }
        {
          (!success) &&
          formulario()
        }
      </Modal>
    </div>
  )
}
export default FormularioModal