/**
 * TODO: DOMINIO SITE PRUEBAS 
 * @URLDominio : http://localhost:3002/
 * TODO: DOMINIO SITE PRODUCTIVO
 * @URLDominio : http://18.221.91.253:1323/
 * @URLDominio : https://www.dooddo.com/
 * TODO: URL DE SERVIDOR DE PRUEBAS 
 * @URLApi : http://localhost:50797/api
 * @URLApi : http://18.221.91.253:1321/api
 * TODO: URL DE SERVIDOR PRODUCTIVO
 * @URLApi : http://18.221.91.253:1322/api
 * @URLAPI : https://service.dooddo.com/api
 * 
 */

export const SITE_DOMAIN = "https://www.dooddo.com/"
export const APIURL = "https://service.dooddo.com/api"
export const SITE_CLIENT_URL = "https://ahorro.dooddo.com/"
export const ASSETS_URL = "https://assets.dooddo.com/"
export const APITOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IlNpdGlvIiwiQVBhdGVybm8iOiJXZWIiLCJVc2VyTmFtZSI6InNpdGlvd2ViQGRvb2Rkby5jb20iLCJUb2tlbiI6IlBhc3N3b3JkIiwiZW1haWwiOiJzaXRpb3dlYkBkb29kZG8uY29tIiwiVXNlcklkIjoiMzk0NmY4NWItNTFhMy00YWE2LThjMzAtOTUzNWZmOWM0MjZlIiwibmJmIjoxNzA1MDMxNjIzLCJleHAiOjIwMjA2MDc2MjMsImlhdCI6MTcwNTAzMTYyMywiaXNzIjoiRG9vZGRvLkFkbWluIiwiYXVkIjoiRG9vZGRvLkFkbWluLkFwaSJ9.82Xam7p9n_e_T7xK0w-T-tcQ96WKz2mhr1oYzANI6XM"

/**
 * TODO: PortalId y formGuid para conexion con hubspod y formulario de envio
 * @PortalId : Id de la cuenta de hubspod
 * @FormGuid : Id de el formulario creado en hubspod
 * @UrlFormHubspot : URL endpoint con hubspot
 */
 export const portalId = '21252848'
 export const formGuid = '4ffe8c35-6bcb-4961-aea3-ba05c34065e4'
 export const UrlFormHubspot = 'https://api.hsforms.com/submissions/v3/integration/submit/'

 