import axios from "axios"
import { APIURL } from "../config/siteConfig"
import { getCabecera } from "../utils/getCabecera"
import { getError } from "../utils/errors"
const urlCotizacion = "Cotizacion"

export const getCotizacion = async ({ idCotizacion }) => {
  return await axios.get(
    `${APIURL}/${urlCotizacion}/Get/${idCotizacion}`, {
    headers: getCabecera(),
  }
  ).then((Response) => {
    return Response.data;
  }).catch((Error => {
    return {};
  }))
}

export const putAceptarCotizacion = async ({ IdCotizacion }) => {
  return await axios.put(`${APIURL}/${urlCotizacion}/Accepted/${IdCotizacion}`, {

  }, {
    headers: getCabecera(),
  }
  ).then((Response) => {
    console.log("🚀 ~ ).then ~ Response:", Response)
    return Response.data;
  }).catch((Error => {
    return getError(Error);
  }))
}