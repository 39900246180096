export function obtenerFechasFormateadas(data) {
    const fechasPorAnio = {};

    data.forEach(bloque => {
        bloque.Salidas.forEach(salida => {
            const fecha = new Date(salida.FechaSalida);
            // Verificar si son fechas postumas
            const esPostuma = bloque.Descripcion.includes("POSTUMO") ? '*' : '';
            let dia = fecha.getDate();
            dia = dia < 10 ? `0${dia}` : dia;

            const mes = fecha.toLocaleDateString('es-ES', { month: 'long' });
            const anio = fecha.getFullYear();

            if (!fechasPorAnio[anio]) {
                fechasPorAnio[anio] = {};
            }

            if (!fechasPorAnio[anio][mes]) {
                fechasPorAnio[anio][mes] = [];
            }

            fechasPorAnio[anio][mes].push(`${dia}${esPostuma}`);
        });
    });

    const resultados = Object.keys(fechasPorAnio)
        .sort((a, b) => a - b)  // Ordenar por año
        .map(anio => {
            const meses = Object.keys(fechasPorAnio[anio])
                .sort((a, b) => new Date(`${anio}-${a}`) - new Date(`${anio}-${b}`))  // Ordenar por mes
                .map(mes => {
                    const dias = fechasPorAnio[anio][mes].sort((a, b) => a - b).join(', ');
                    return `${mes.charAt(0).toUpperCase() + mes.slice(1)}: ${dias}`;
                });

            return { [anio]: meses };
        });

    return resultados;
}

export function obtenerFechasFormateadasV1(data) {
    const fechasPorMes = {};

    data.forEach(bloque => {
        bloque.Salidas.forEach(salida => {
            const fecha = new Date(salida.FechaSalida);
            const esPostuma = bloque.Descripcion.includes("POSTUMO") ? '*' : '';
            let dia = fecha.getDate();
            dia = dia < 10 ? `0${dia}` : dia;

            const mes = fecha.toLocaleDateString('es-ES', { month: 'long' });
            const anio = fecha.getFullYear();

            const claveMesAnio = `${anio}-${fecha.getMonth() + 1}-${mes}`;

            if (!fechasPorMes[claveMesAnio]) {
                fechasPorMes[claveMesAnio] = [];
            }

            fechasPorMes[claveMesAnio].push(`${dia} ${esPostuma}`);
        });
    });

    const resultados = Object.keys(fechasPorMes)
        .sort((a, b) => new Date(a.split('-')[0], a.split('-')[1] - 1) - new Date(b.split('-')[0], b.split('-')[1] - 1))
        .map(claveMesAnio => {
            const diasOrdenados = fechasPorMes[claveMesAnio].sort().join(', ');
            const [anio, , mes] = claveMesAnio.split('-');
            return `${mes.charAt(0).toUpperCase() + mes.slice(1)} ${anio}: ${diasOrdenados}`;
        });

    return resultados;
}