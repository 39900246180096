import {useEffect, useState} from "react";
import { getProveedorPaquete } from "../services/proveedorService";
export const usePaqueteProveedor = ({ClavePaquete}) =>{
    const [paqueteProveedor, setPaqueteProveedor] = useState([]);
    useEffect(() =>{
        Promise.all([getProveedorPaquete({ClavePaquete})]).then(Response => {
            setPaqueteProveedor(Response[0]);
        }).catch(Error => {
            setPaqueteProveedor([]);
        })
        return () =>{
            setPaqueteProveedor([]);
        }
    },[ClavePaquete])
    return [paqueteProveedor];
}