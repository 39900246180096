import {useEffect, useState} from "react"
import { getSuplmentosByPaqueteId } from "../services/suplementoServices"
export const useSuplemento = ({idPaquete}) =>{
    const [suplementos, setSuplementos] = useState([]);
    useEffect(() =>{
        Promise.all([getSuplmentosByPaqueteId({idPaquete})]).then(Response => {
            setSuplementos(Response[0]);
        }).catch(Error => {
            setSuplementos([]);
        })
        return () =>{
            setSuplementos([]);
        }
    },[idPaquete])
    return [suplementos];
}