import "./index.css";
import Contain from "../../global/Contain";
import Title from "../../global/Title";
import { SITE_DOMAIN } from "../../config/siteConfig";
import Header from "../../global/Header";
import Footer from "../../global/Footer";
const TerminosCondiciones = () => {
    return (
        <div>
            <Header />
            <Contain>
                <Title titulo="Términos y condiciones" />
                <div className="Terminos-main">
                    <p><strong>LEA LOS TÉRMINOS Y CONDICIONES CUIDADOSAMENTE ANTES DE USAR
                        ESTE SITIO</strong></p>
                    <p><strong>INTRODUCCI</strong><strong>Ó</strong><strong>N.-</strong><strong>&nbsp;</strong>Bienvenido al
                        sitio de internet&nbsp;<a href={SITE_DOMAIN}>www.dooddo.com</a> &nbsp;, colectivamente denominado como el&nbsp;<strong>“Sitio”.</strong>&nbsp;El uso que haga de este Sitio está
                        expresamente condicionado a su aceptación de los presentes Términos y
                        Condiciones. Al usar este sitio, manifiesta su consentimiento con los Términos
                        y Condiciones. Dooddo.com&nbsp; se reserva el
                        derecho de adicionar o modificar los Términos y Condiciones en cualquier
                        momento. Todos los términos adicionados o modificados causarán efecto en el
                        momento en que éstos sean actualizados. El uso continuo de este sitio incluso
                        posterior a la publicación de los cambios realizados a los Términos y Condiciones
                        significará que Usted acepta dichos cambios. Por favor regrese periódicamente a
                        esta página para consultar cualquier cambio.</p>



                    <p><strong>DEFINICIONES.-</strong><strong>&nbsp;</strong>Para todos los
                        efectos de los Términos y Condiciones aquí establecidos, se entenderán las
                        siguientes definiciones: <strong>ACELERADORA DE VIAJES.-</strong>&nbsp;Empresa (Aceleradora de Viajes S.A
                        de C.V) que contrata y/o actúa como&nbsp;<strong>intermediaria</strong>&nbsp;a través de sus servicios de
                        reservación, en beneficio de un Usuario-Turista, respecto de los servicios
                        turísticos proporcionados de manera directa por el Proveedor. (En lo sucesivo
                        denominada de aquí en adelante como&nbsp;<strong>“Dooddo”</strong>).&nbsp;<a href={SITE_DOMAIN}>www.dooddo.com</a><strong> .-</strong><strong>&nbsp;</strong>Página web de
                        Dooddo que actúa como&nbsp;<strong>intermediaria</strong>&nbsp;y como motor de reservaciones,
                        ofreciendo a través del internet al Usuario-Turista, los diversos servicios
                        turísticos que proporciona de manera directa el Proveedor. (En lo sucesivo
                        denominado de aquí en adelante como el&nbsp;<strong>“Sitio”</strong>).&nbsp;<strong>USUARIO-TURISTA.-</strong>&nbsp;Persona que viaja temporalmente y/o
                        que utiliza el sitio&nbsp;<a href={SITE_DOMAIN}><strong>www.dooddo.com</strong></a><strong> </strong>, con el objeto de hacer uso de la&nbsp;<strong>intermediación</strong><strong>&nbsp;</strong>y el motor de
                        reservaciones de Dooddo&nbsp; y con el fin
                        último de disfrutar cualquiera de los servicios turísticos proporcionados de
                        manera directa por el Proveedor. (En lo sucesivo denominado de aquí en adelante
                        como&nbsp;<strong>“Usted”</strong>).&nbsp;<strong>PROVEEDOR.-</strong>&nbsp;Persona física o moral que
                        proporciona en forma directa los servicios turísticos a el Usuario-Turista,
                        contratados, reservados o adquiridos por éste último a través de la intermediación
                        y motor de reservaciones del sitio de dooddo.com .</p>



                    <p><strong>DECLARACIONES.-</strong><strong>&nbsp;</strong>Usted
                        declara bajo protesta de decir verdad, compareciendo por su propio y personal
                        derecho y/o a través de su apoderado legal, que es una persona física o moral,
                        mayor de edad, hábil, y con los medios suficientes ó con capacitad económica
                        para obligarse bajo los presentes Términos y Condiciones, así como con interés
                        en adquirir los servicios de intermediación y reservación que aquí se
                        especifican, y que para ello recurre a&nbsp;&nbsp;Dooddo&nbsp;
                        a efecto de que funja como mera&nbsp;<strong>intermediaria</strong>&nbsp;entre
                        Usted y los Proveedores prestadores directos de servicios turísticos.

                        Usted declara bajo protesta de decir verdad, que
                        reconoce que los servicios proporcionados por Dooddo y su Sitio, consisten&nbsp;<strong>UNICA
                            Y EXLUSIVAMENTE EN LA INTERMEDIACI</strong><strong>Ó</strong><strong>N
                                ENTRE USTED Y EL PROVEEDOR DIRECTO DE LOS SERVICIOS.</strong><strong>&nbsp;</strong><strong> Dooddo</strong>&nbsp;declara
                        (a) ser una sociedad mercantil debidamente constituida conforme a las leyes
                        mexicanas. (b) Que cuenta con autorización de la Secretaria de Turismo para
                        prestar servicio de Agencia de Viajes con el No. 04090151376, &nbsp;así mismo se encuentra registrada ante las
                        autoridades Hacendarias con el Registro Federal de Contribuyentes número&nbsp;<strong>AVI160825MVA</strong>.
                        (c) Que su domicilio se encuentra ubicado en calle Rio Nazas No171 L1, Col.
                        Cuauhtémoc Del. Cuauhtémoc CDMX CP 06500. (d) Que su objeto social consiste en
                        la facultad para: Actuar como&nbsp;<strong>intermediaria</strong>&nbsp;para
                        la reservación de espacios en los medios de transporte y expedir de parte de
                        los transportistas y a favor de los turistas los boletos correspondientes;
                        servir de&nbsp;<strong>intermediario</strong>&nbsp;entre
                        los turistas y los prestadores de servicios de transportes de cualquier género;
                        reservar a los turistas habitaciones y demás servicios conexos, en hoteles y
                        establecimientos de hospedaje; prestar a los turistas servicios de reservación
                        de sitios de atracción turística; servir de&nbsp;<strong>intermediario</strong>&nbsp;entre
                        los demás prestadores de servicios turísticos y otras agencias de viajes;
                        proporcionar servicio de información turística y difundir el material de
                        propaganda de esta índole que sea de distribución gratuita. (e) Que para
                        efectos de los presentes Términos y Condiciones, únicamente actúa como&nbsp;<strong>INTERMEDIARIA</strong>&nbsp;entre
                        Usted&nbsp;&nbsp;y
                        los Proveedores de dichos servicios turísticos,
                        y que para tal objeto, cuenta con la capacidad técnica
                        y administrativa, así como con los
                        elementos materiales y humanos necesarios&nbsp; para reservar por cuenta y a petición de Usted los servicios a los que hace
                        referencia estos Términos y
                        Condiciones. (f) Que con base en las Declaraciones que anteceden, acepta los
                        presentes Términos y Condiciones.</p>



                    <p><strong>OBJETO.-</strong><strong>&nbsp;</strong>El
                        objeto consiste en la&nbsp;<strong>INTERMEDIACI</strong><strong>Ó</strong><strong>N</strong>&nbsp;que
                        existirá entre Dooddo, Usted y los Proveedores, en donde Doodoo para los
                        efectos de los presentes Términos y Condiciones, actuará única y exclusivamente
                        como&nbsp;<strong>INTERMEDIARIA</strong>,
                        proporcionando a Usted a través de su Sitio de manera enunciativa más no
                        limitativa, el servicio de reservación de transporte terrestre, y aérea;
                        reservación en hoteles y establecimientos de hospedaje; reservación de sitios
                        de atracción turística; reservación de paquetes de viajes; entre otros, Así,
                        una vez que las reservaciones le sean confirmadas por Dooddo conteniendo los
                        servicios turísticos por Usted elegidos (de acuerdo a su disponibilidad), se
                        concluirá con la prestación del servicio de intermediación por parte de Dooddo,
                        ya que dichos servicios turísticos serán proporcionados a Usted por los
                        Proveedores de manera directa.</p>



                    <p><strong>USO DEL SITIO.-</strong><strong>&nbsp;</strong>Dooddo / Dooddo.com le otorga una licencia
                        limitada, intransferible, revocable, para usar este Sitio de acuerdo a los
                        presentes Términos y Condiciones. Usted debe usar este sitio solamente para
                        realizar o comprar reservaciones legítimas, contratar los servicios de
                        modalidad presupuesto jutno con sus acompañantes&nbsp; y
                        no lo podrá usar para ningún otro propósito, incluida pero sin limitación a
                        hacer alguna reservación especulativa, falsa o fraudulenta. Este Sitio y su
                        contenido referido en él, de manera enunciativa más no limitativa: los textos,
                        párrafos, enunciados, combinación específica de palabras, letras o elementos
                        fonéticos, gráficos, iconos de botones, marcas, logotipos, obras de diseño gráfico,
                        fotografías, avisos comerciales, patentes, método comercial, combinación
                        específica de colores y formas, material editorial, formatos impresos, formatos
                        de búsqueda, descargas digitales, compilaciones de datos, códigos fuente,
                        software, son propiedad exclusiva de Best Day o bien cuenta con todas las
                        Licencias y Autorizaciones para tal uso, por tanto Usted no puede copiar,
                        reproducir, volver a publicar, cargar, modificar, transmitir o distribuir dicho
                        contenido sin tener la autorización previa y por escrito de Dooddo, excepto, en
                        los casos (a) en que Usted descargue para ver e imprimir el material que se
                        encuentra contenido en este Sitio ó (b) descargue o imprima el material que le
                        sea enviado a su correo electrónico por parte de Dooddo, en ambas situaciones
                        Usted única y exclusivamente podrá utilizarlo para su uso personal, debiendo
                        incluir el material impreso siempre la leyenda “©&nbsp;Aceleradora de Viajes&nbsp; S.A. DE C.V.&nbsp;
                        Todos los derechos reservados.” quedando estrictamente prohibida
                        cualquier reproducción total o parcial del Sitio o cualquier uso de su
                        contenido destinado al lucro comercial. Usted no puede usar ningún dispositivo
                        &#8220;robótico,&#8221; de &#8220;spider&#8221; u otro dispositivo automático,
                        programa, algoritmo o metodología que tenga procesos o funciones similares, o
                        ningún proceso manual, para monitorear o copiar cualquiera de las páginas Web,
                        datos o contenido, código fuente, que se encuentre en este Sitio en ningún caso
                        y sin el consentimiento previo y por escrito de Dooddo. De acuerdo a lo
                        anterior, Usted manifiesta estar de acuerdo en que no copiará, publicará,
                        transmitirá, modificará o transferirá de ninguna otra manera los datos o
                        contenido de este Sitio a ningún computador, servidor, sitio Web, o a otro
                        medio de distribución masiva, para uso de ninguna empresa comercial, cualquier
                        sitio, página web, o empresa nacional o extranjera que represente una actividad
                        económica. Usted también manifiesta estar de acuerdo en que no usará ningún
                        dispositivo de ninguna índole, software o rutina para interferir con el desempeño propio de este
                        sitio. El uso no autorizado que Usted haga de este sitio ó cualquier
                        reproducción total o parcial del Sitio y/o contenido y de su material, puede
                        violar las leyes que rigen la propiedad de Derechos de Autor, Derechos de
                        Propiedad Industrial y otras leyes. Usted deberá respetar todos los avisos
                        sobre Derechos de Autor y Derechos de Propiedad Industrial y no podrá alterar,
                        ocultar, o destruir ninguno de estos avisos. El uso de tal material en
                        cualquier otro sitio web o en cualquier ambiente de computadores en red, está
                        prohibido. Está prohibido por Dooddo que usted coloque o transmita cualquier
                        material que vaya en contra de la ley, amenazante, injurioso, difamatorio,
                        obsceno, indecente, pornográfico, profano, o cualquier material que pueda
                        constituir o animar a otros a tener conductas que se puedan considerar delitos
                        criminales, que deriven en delitos civiles, o que de cualquier otra forma
                        violen cualquier ley. Además, Usted tiene prohibido exhibir o transmitir
                        cualquier información que (a) infrinja los derechos que tengan otras personas,
                        o que violen su privacidad, o derechos de publicidad, (b) esté protegida por
                        Derechos de Autor, de Marca Registrada u otros Derechos de Propiedad, a menos
                        que se tenga la autorización expresa y por escrito de Dooddo o del propietario
                        de tales derechos, (c) que contenga un virus, bug o cualquier otro ítem
                        peligroso o (d) que sea usada para confabularse ilegalmente en contra de otra
                        persona en detrimento de su derecho a comercializar o a competir. Usted será el
                        único responsable por cualquier daño que se cause por cualquier violación a los
                        Derechos de Autor, de Propiedad Industrial, o a cualquier otra clase de
                        Propiedad de Derechos, o por cualquier otro daño que se ocasione por el mal uso que usted haga
                        de este sitio.</p>



                    <p><strong>EDAD Y
                        RESPONSABILIDAD.-</strong><strong>&nbsp;</strong>Usted manifiesta, independientemente de su País
                        de procedencia, que tiene la edad legal suficiente para usar este sitio y
                        suscribir y obligarse ante los presentes Términos y Condiciones, así como para
                        hacerse responsable a las obligaciones legales que lo unan con cualquier
                        responsabilidad en que incurra por el uso de este Sitio. Usted comprende que
                        será económicamente responsable por todo el uso que Usted haga de este Sitio y
                        aquellos que lo usen utilizando su información para ingresar a él.</p>



                    <p><strong>PROPIEDAD
                        INDUSTRIAL.-</strong><strong>&nbsp;</strong>Usted reconoce que la marca “DOODDO” “DOODDO
                        AGENTE”, “DOODDO.COM”, “DOODDO EVENTOS” “DOODDO SOLUCIONES” “DOODDO GRUPOS””,
                        entre otras, son marcas registradas o en trámite de registro para uso exclusivo
                        de DOODDO / ACELERADORA DE VIAJES S.A DE C.V. Y de manera enunciativa más no
                        limitativa las demás marcas, logotipos, nombres comerciales, patentes, modelos
                        de utilidad y métodos comerciales, cartera de clientes, que aparecen ó
                        funcionan en este Sitio, constituyen derechos de Propiedad Industrial que se
                        encuentran registrados o en proceso de registro, son propiedad exclusiva de
                        DOODDO, de sus afiliadas, ó terceras partes contratantes, y fueron
                        proporcionadas para el uso del Sitio, por tal razón DOODDO cuenta con las
                        autorizaciones y/o licencia de uso respectiva y por tanto Usted no puede
                        copiar, reproducir, volver a publicar, cargar, publicar, modificar, transmitir
                        o distribuir dichos derechos ni el contenido de este Sitio, sin tener la
                        autorización previa y por escrito de DOODDO, excepto, en los casos (a) en que
                        Usted descargue para ver e imprimir el material que se encuentra contenido en
                        este Sitio ó (b) descargue o imprima el material que le sea enviado a su correo
                        electrónico por parte de DOODDO, en cuyas situaciones, Usted única y
                        exclusivamente podrá utilizarlo para su uso personal, debiendo incluir siempre
                        la leyenda “©&nbsp;ACELERADORA DE
                        VIAJES S.A DE C.V Todos los derechos reservados.”. En razón de lo anterior,
                        nada de lo contenido en este Sitio incluyendo sus derechos protegidos por la
                        Ley de Propiedad Industrial aplicable, por las Leyes y Tratados
                        Internacionales, podrá ser considerado por Usted como una licencia o derecho a
                        usar cualquiera de las Marcas Comerciales, o demás derechos de Propiedad Industrial
                        sin el previo consentimiento por escrito de DOODDO, o en su caso de los
                        titulares de tales derechos. Y se le prohíbe utilizarlas en cualquier otra
                        aplicación que no sea permitida por DOODDO o cualquier otro uso comercial.
                        Usted acuerda en no alterar la Propiedad Industrial de DOODDO en modo alguno,
                        ni se permitirá la acción que de cualquier manera que pueda menoscabar,
                        denigrar o disminuir los derechos de Propiedad Industrial de DOODDO. Usted se
                        compromete a no utilizar las marcas o nombres comerciales de DOODDO como
                        nombres de dominio ni registrarlos como parte de un nombre de dominio, o bien
                        su colocación como parte de Publicidad en Internet sin previo consentimiento
                        por escrito de DOODDO. DOODDO se reserva el derecho de vigilar la calidad del
                        uso de su Propiedad Industrial, además, notificará a Usted de inmediato por
                        escrito en el caso de percatarse de la existencia de cualquier violación de su
                        Propiedad Industrial. Cualquier uso inapropiado del contenido y de la Propiedad
                        Industrial de este Sitio es susceptible de violación de las Leyes de Propiedad
                        Industrial, Mexicanas y Extranjeras, de Tratados Internacionales así como
                        derechos consignados en el Código Civil, Penal y de Comercio. Si Usted
                        incurriera en algún uso inapropiado del contenido y Propiedad Industrial de
                        este Sitio, DOODDO tendrá el derecho de requerirle (a) que deje de utilizar el
                        contenido que DOODDO considere vulnera sus derechos de Propiedad Industria. (b)
                        que deje de copiar total o parcialmente, reproducir, volver a publicar, cargar,
                        publicar, transmitir, distribuir o modificar el uso del contenido y de los
                        Derechos de Propiedad Industrial de este Sitio (c) podrá requerirle establecer
                        un uso adecuado de sus derechos de Propiedad Industrial o bien (d) podrá
                        iniciar los procedimientos legales necesarios para defender sus derechos de
                        Propiedad Industrial.</p>



                    <p><strong>DERECHOS DE
                        AUTOR.-</strong><strong>&nbsp;</strong>Usted reconoce de manera enunciativa más no
                        limitativa que todos los textos, párrafos, enunciados, combinación específica
                        de palabras, letras o elementos fonéticos, gráficos, iconos de botones, obras
                        de diseño
                        gráfico, fotografías, combinación específica de colores y formas, material
                        editorial, formatos impresos, formatos de búsqueda, descargas digitales, compilaciones
                        de datos, códigos fuente, software, programas de computación, bases de datos,
                        anuncios publicitarios, que aparecen ó funcionan en este Sitio, constituyen
                        Derechos de Autor que se encuentran registrados o en proceso de registro y son
                        propiedad exclusiva de DOODDO o del proveedor que presta el servicio, con
                        titularidad de derechos patrimoniales, o sobre los cuales se cuenta con
                        Licencia de uso o Autorización respectiva y fueron proporcionadas para el uso
                        del Sitio, por tanto Usted no puede copiar, reproducir, volver a publicar,
                        cargar, publicar, transmitir, modificar o distribuir dichos derechos ni el
                        contenido de este Sitio, sin tener la autorización previa y por escrito de
                        DOODDO, excepto, en los casos (a) en que Usted descargue para ver e imprimir el
                        material que se encuentra contenido en este Sitio ó (b) descargue o imprima el
                        material que le sea enviado a su correo electrónico por parte de DOODDO, en
                        cuyas situaciones, Usted única y exclusivamente podrá utilizarlo para su uso
                        personal, debiendo incluir la leyenda “©&nbsp;ACELERADORA DE VIAJES S.A. DE C.V. &nbsp;Todos los derechos reservados.”. En razón de
                        lo anterior, nada de lo contenido en este Sitio incluyendo los derechos
                        protegidos por la Ley Federal de Derechos de Autor aplicable, y por las Leyes y
                        Tratados Internacionales, podrá ser considerado por Usted como una licencia o
                        derecho a usar cualquiera de estos Derechos de Autor sin el previo
                        consentimiento por escrito de DOODDO. Y se le prohíbe usarlas en cualquier
                        aplicación que no sea permitida por DOODDO o cualquier otro uso comercial.
                        Usted acuerda en no alterar los Derechos de Autor de DOODDO en modo alguno, ni
                        se permitirá la acción que de cualquier manera que pueda menoscabar, denigrar o
                        disminuir los Derechos de Autor de DOODDO. Usted se compromete a no colocar
                        todo o en parte de los Derechos de Autor de DOODDO como publicidad en internet
                        sin previo consentimiento por escrito de DOODDO. DOODDO se reserva el derecho
                        de vigilar la calidad del uso de sus Derechos de Autor, además, notificará a
                        Usted de inmediato por escrito en el caso de percatarse de la existencia de
                        cualquier violación de sus Derechos de Autor. Cualquier uso inapropiado del
                        contenido y de los Derechos de Autor de este Sitio es susceptible de violación
                        de las Leyes Federales de Derechos de Autor, Mexicanas y Extranjeras, de
                        Tratados Internacionales así como derechos consignados en el Código Civil,
                        Penal y de Comercio. Si Usted incurriera en algún uso inapropiado del contenido
                        y Derechos de Autor de este Sitio, DOODDO tendrá el derecho de requerirle (a)
                        que deje de utilizar el contenido y Derechos de Autor del Sitio, (b) que deje
                        de copiar total o parcialmente, reproducir, volver a publicar, cargar,
                        publicar, transmitir, distribuir o modificar el uso del contenido y de los
                        Derechos de Autor de este Sitio (c) podrá requerirle establecer un uso adecuado
                        de los Derechos de Autor o bien (d) podrá iniciar los procedimientos legales
                        necesarios para defender sus Derechos de Autor.</p>



                    <p><strong>PRIVACIDAD Y
                        SEGURIDAD.-</strong><strong>&nbsp;</strong>Usted manifiesta que ha leído la&nbsp;<strong>POLITICA
                            DE PRIVACIDAD Y SEGURIDAD</strong>&nbsp;de DOODDO, los términos incorporados en ella, y
                        está de acuerdo en que los términos de tal política son razonables. Usted
                        consiente en que su información personal sea usada por DOODDO y/o por sus
                        proveedores y distribuidores terceras partes de acuerdo con los términos de la
                        Política de Privacidad de DOODDO y para los propósitos establecidos en ella.</p>



                    <p><strong>REVISI</strong><strong>Ó</strong><strong>N DE LAS
                        TRANSMISIONES.-</strong><strong>&nbsp;</strong>DOODDO.COM / DOODDO / ACELERADORA DE VIAJES S.A
                        DE C.V podrá monitorear y revisar cualquier información que se transmita o
                        reciba por medio de este sitio y se reserva el derecho a censurar, editar,
                        suprimir o prohibir la transmisión o la recepción de cualquier información que
                        DOODDO considere inapropiada o que viole estos términos y condiciones. Durante
                        el tiempo que dure el monitoreo, puede que la información sea examinada,
                        grabada o copiada y al usar este sitio usted manifestará que acepta tal
                        monitoreo y revisión. Usted manifiesta además estar de acuerdo en que si usted
                        hace sugerencias, presenta ideas, comentarios o hace preguntas, o coloca
                        cualquier otra información en este sitio, usted le garantiza a DOODDO, el
                        derecho no exclusivo, sin regalías, perpetuo, irrevocable y con derecho de
                        sublicencia total para usar, reproducir, modificar, adaptar, publicar,
                        traducir, crear trabajos derivados, distribuir y exhibir tal contenido en
                        cualquier forma, medio o tecnología. DOODDO no se responsabiliza ni tendrá
                        ninguna responsabilidad por ningún contenido que sea situado o presentado por
                        Usted.</p>



                    <p><strong>LIMITACI</strong><strong>Ó</strong><strong>N DE
                        RESPONSABILIDAD.-</strong><strong>&nbsp;</strong>DOODDO NO SERA RESPONSABLE, NI ASUME NINGUNA
                        RESPONSABILIDAD POR CUALQUIER DAÑO O VIRUS QUE PUEDA INFECTAR SU COMPUTADOR O
                        CUALQUIER OTRA PROPIEDAD DE USTED DEBIDO AL ACCESO, USO O NAVEGACIÓN QUE HAGA DE ESTE
                        SITIO, O POR LA DESCARGA DE CUALQUIER MATERIAL, DATO, TEXTO, IMAGEN, VIDEO O
                        AUDIO DESDE EL SITIO. EN NINGUN CASO DOODDO SERA RESPONSABLE POR CUALQUIER
                        LESION, DAÑO,
                        PERJUICIO, PERDIDA, RECLAMO O CUALQUIER DAÑO ESPECIAL, PUNITIVO, INDIRECTO, INCIDENTAL POR
                        NEGLIGENCIA, O ILICITO, QUE RESULTE DE, (I) CUALQUIER USO DE ESTE SITIO O DEL
                        CONTENIDO QUE AQUÍ
                        SE ENCUENTRE, (II) CUALQUIER FALLA O DEMORA (INCLUYENDO, SIN QUE HAYA LIMITE,
                        EL USO DE, O LA IMPOSIBILIDAD DE USAR CUALQUIER COMPONENTE DE ESTE SITIO PARA
                        LOS SERVICIOS DE RESERVACION O EMISION DE BOLETOS), O (III) EL FUNCIONAMIENTO O
                        NO FUNCIONAMIENTO DEL PROVEEDOR, INCLUIDO, PERO SIN ESTAR LIMITADO A, EL NO
                        FUNCIONAMIENTO RESULTADO DE LA BANCARROTA, REORGANIZACION, DECLARACION DE
                        INSOLVENCIA, DISOLUCION O LIQUIDACION.</p>



                    <p><strong>RESPONSABILIDAD.-</strong><strong>&nbsp;</strong>Usted deberá responder de los daños que genere a
                        DOODDO, que resulten de cualquier mal uso o uso incorrecto que pudiera hacer
                        del Sitio, o sobre cualquier operación fraudulenta o con fines contrarios a los
                        servicios prestados en el sitio o aquellos que vayan en contra de las leyes y
                        reglamentos vigentes y aplicables.</p>



                    <p><strong>ENLACES.-</strong><strong>&nbsp;</strong>Este Sitio contiene enlaces a otros sitios Web
                        los cuales se proporcionan únicamente para su conveniencia y no como respaldo a
                        DOODDO y ellos pertenecen a los proveedores o distribuidores terceras partes de
                        los contenidos de tales otros sitios Web. DOODDO no será responsable por el
                        contenido de ningún otro sitio Web y no representa o garantiza ningún otro
                        sitio Web o el contenido o material de tales sitios. Si Usted decide acceder a
                        otros sitios Web, lo hace bajo su propia responsabilidad.</p>



                    <p><strong>SITIOS
                        FACEBOOK, TWITTER, INSTAGRAM, SNAPCHAT, PINTEREST,&nbsp; YOU TUBE.-</strong><strong>&nbsp;</strong>Este Sitio contiene enlace a diversas páginas
                        sociales, entre ellas, las páginas de Facebook con nombre de usuario:&nbsp;<strong>DOODDO/DOODDOMX</strong>&nbsp;página
                        de twitter con nombre de usuario: @<strong>DOODDOMX </strong>&nbsp;y
                        Instagram: @DOODDOMX&nbsp; You Tube con
                        usuario&nbsp;<strong>DOODDOMX,</strong>&nbsp;cuentas
                        administradas por DOODDO; Usted reconoce que dichas páginas son exclusivamente
                        para uso informativo y difusión social de DOODDO, y DOODDO no será responsable
                        de manera enunciativa más no limitativa por el contenido, información,
                        publicación de terceros, comentarios, fotografías, videos, contenido de
                        hastags, ni cualquier otro material fuera del alcance de la administración
                        directa de DOODDO, ni por el material que compartido en dichos sitios sociales
                        por Usted o cualquier tercero y fuera de la administración de DOODDO pueda
                        violar los derechos de propiedad industrial o derechos de autor. Usted es
                        responsable del uso que le pueda dar a dichos sitios, y Usted libera de toda
                        responsabilidad a DOODDO por cualquier uso incorrecto o mal intencionado que
                        pudiera hacer de los mismos, o por publicaciones, información, comentarios, y
                        material diverso que pudiera resultar ofensivo, insidioso, erróneo,
                        pornográfico o que pudiera incitar a la violencia, rebelión, huelga, o
                        cualquier actividad no permitida por la ley y las buenas costumbres.</p>



                    <p><strong>INFORMACI</strong><strong>Ó</strong><strong>N VERDADERA.-</strong><strong>&nbsp;</strong>Usted &nbsp;reconoce y acepta su total obligación y
                        responsabilidad de proporcionar datos veraces y correctos sobre las edades,
                        sexo, nombres o apellidos tanto de Usted como de los demás
                        usuarios-turistas que lo acompañen o que lo comisionen para hacer uso de éste
                        Sitio, así como de los datos e información completa para la formación del
                        itinerario y la reservación de servicios terrestres y marítimos (hoteles,
                        visitas, autos de alquiler, restaurantes, cruceros, etc) liberando a&nbsp;DOODDO de cualquier responsabilidad por cambios
                        de itinerario generados por datos mal proporcionados, o por cualquier error en
                        los datos proporcionados por&nbsp;Usted para la emisión o compra de boletos aéreos como puede
                        ser el caso de edades, sexo, nombres, apellidos, fechas, formas de pago, rutas,
                        líneas aéreas, horarios, clases, categorías, condiciones especiales, etc.,
                        apegándose en su caso a las cláusulas de cancelación para servicios aéreos,
                        terrestres, marítimos o de cruceros expresadas en estos Términos y Condiciones.</p>



                    <p><strong>RESPETO DE LOS
                        REGLAMENTOS Y CONDICIONES DE LOS SERVICIOS.-</strong><strong>&nbsp;</strong>Usted se
                        compromete a apegarse y a respetar los reglamentos y condiciones de servicio
                        establecidas por cada uno de los Proveedores y prestadores directos de
                        servicios contratados por Usted a través de la intermediación de&nbsp;DOODDO, por lo que&nbsp;DOODDO debe
                        hacer de su conocimiento las que sean más importantes, no obstante el Proveedor
                        podrá hacer de su conocimiento nuevas o adicionales condiciones de servicio
                        sobre las cuales DOODDO no tiene responsabilidad alguna y en consecuencia
                        declina cualquier responsabilidad que pudiera derivar por su incumplimiento.
                        Así mismo,&nbsp;Usted deberá por medios propios proveerse de
                        los pasaportes o documentos de migración requeridos por las autoridades de
                        los Estados Unidos Mexicanos, y de los países de destino ó de tránsito, tales
                        como visas, permisos sanitarios, y todos aquellos documentos requeridos por las
                        autoridades Aduaneras, Aéreas, Marítimas, Aeroportuarias, Federales, Estatales,
                    </p>



                    <p><strong>ANTICIPACION O
                        LIQUIDACION DEL IMPORTE.-</strong><strong>&nbsp;</strong>Ambas partes convienen en que el pago de anticipos o
                        liquidación del importe de los servicios objeto de los presentes Términos y
                        Condiciones por parte de&nbsp;Usted, así como el uso de
                        cupones, boletos, cortesías, o cualquier otro documento expedido en su favor,
                        implica de su parte la aceptación total de estos Términos y Condiciones.</p>



                    <p><strong>CONVENIOS
                        ADICIONALES CON PROVEEDORES.-</strong><strong>&nbsp;</strong>DOODDO queda relevada de cualquier responsabilidad
                        derivada de convenios adicionales y fuera de la intermediación con DOODDO que
                        se hayan celebrado entre&nbsp;Usted y demás Proveedores
                        prestadores directos de los servicios, como son transportistas aéreos y
                        terrestres, navieras, cruceros, hoteles, arrendadoras de autos, etc.</p>



                    <p><strong>SERVICIOS POR O
                        PARA TERCERAS PERSONAS.-</strong><strong>&nbsp;</strong>En el caso de que&nbsp;Usted contrate
                        los servicios de&nbsp;DOODDO a través del Sitio, por
                        cuenta de diversa agencia de viajes o de cualquier tercero, o bien los contrate
                        Usted a favor de terceras personas, Usted está de acuerdo que todos los sujetos
                        antes mencionados se sujetarán invariablemente a los presentes Términos y
                        Condiciones. Para el caso de que&nbsp;Usted contrate
                        los servicios de&nbsp;DOODDO&nbsp;por cuenta de
                        diversa agencia de viajes, o cualquier tercero, fungiendo éstos como
                        intermediarios, DOODDO &nbsp;se deslinda de
                        cualquier responsabilidad o problema generado por el incumplimiento de la
                        intermediaria o ACELERADORA DE VIAJES/AGENCIA DE VIAJES y en el mismo sentido
                        por datos mal proporcionados por la intermediaria o agencia de viajes para la
                        reservación de servicios terrestres, aéreos, marítimos, de navieras y cruceros,
                        o bien, por la negligencia en que pudiera incurrir dicha intermediaria al
                        omitir información al usuario-turista o a Usted sobre los procedimientos de
                        contratación, pago y cancelación de los servicios que se adquieren.</p>



                    <p><strong>DESLINDE DE
                        RESPONSABILIDADES.-</strong><strong>&nbsp;</strong><a href={SITE_DOMAIN}><strong>DOODDO</strong><strong>&nbsp;</strong></a>&nbsp;como Aceleradora de Viajes por internet, presta
                        servicios en calidad de agente de reservaciones&nbsp;<strong>intermediaria</strong><strong>&nbsp;</strong>entre Usted y
                        el Proveedor o Proveedores directos de los servicios turísticos promocionados
                        en este Sitio.&nbsp;<a href={SITE_DOMAIN}><strong>dooddo.com</strong><strong>&nbsp;</strong></a>establece
                        negociaciones comerciales dentro de sus propios estándares de calidad y
                        servicio, tales como, pero no limitados a: hospedaje en hoteles, transportación
                        terrestre y otras actividades turísticas afines como tours y excursiones,
                        eligiendo sólo a los proveedores más calificados y de mayor prestigio del
                        mercado. Ahora bien, toda vez que el carácter jurídico de dooddo es el de
                        fungir única y exclusivamente como&nbsp;<strong>INTERMEDIARIA</strong>&nbsp;y no como proveedora directa de los
                        servicios turísticos,&nbsp;<strong>Dooddo</strong><strong>&nbsp;</strong>no asume ni
                        asumirá ninguna responsabilidad generada de relación alguna ante Usted y los
                        Prestadores Finales de Servicios y a su vez Usted libera a dooddo sobre
                        cualquier responsabilidad ante cualquier falla o falta de cumplimiento por
                        parte del Proveedor o Proveedores directos de los servicios turísticos,
                        incluyendo sin limitación alguna cualquier falla o cumplimiento por parte de
                        las aerolíneas, hoteles, proveedores de hospedaje temporal, navieras y toda
                        clase de embarcaciones, proveedores de deportes acuáticos, agencias de renta de
                        autos, agencias de transportación, operadores de tours, instructores de buceo,
                        instructores de esnórquel, instructores de golf, instructores de nado con
                        delfines, instructores de pesca, proveedores de deportes de aventura y deportes
                        extremos, parques acuáticos, parques ecológicos, y en general sobre cualquier
                        falla o falta de cumplimiento por parte de todos aquellos servicios turísticos
                        que sean proporcionados de manera directa por uno o varios Proveedores y no de
                        manera directa por Dooddo; Sin embargo Dooddo, podrá a su libre discreción
                        proporcionarle datos de contacto y/o domicilio del proveedor a efecto de que
                        Usted ejerza la reclamación respectiva o vía de reclamación que Usted considere
                        necesaria. Dooddo como Aceleradora de Viajes y agente&nbsp;<strong>INTERMEDIARIA</strong>&nbsp;de reservaciones, no ofrece, ni
                        ofrecerá ninguna garantía sobre los servicios proporcionados de manera directa
                        por los Proveedores, ni garantiza la posición financiera de los mismos o cualquier
                        reembolso a Usted ocasionado por cualquier pérdida experimentada como resultado
                        de la condición financiera de dicho Proveedor, incluyendo sin limitación
                        alguna, la insolvencia económica, o Concurso Mercantil en que se llegare a
                        declarar dicho Proveedor. En el evento en que el Proveedor falle en el
                        cumplimiento de alguno de sus servicios, por los cuales el pago Usted ya ha
                        realizado, el solo recurso del reembolso deberá tramitarse directamente ante el
                        Proveedor que falló en la prestación de sus servicios, o pueda solicitar la
                        cobertura del seguro en el caso de ser aplicable o ante cualquier otra tercera
                        parte, a menos que el anterior fallo sea ocasionado de manera directa por
                        DOODDO. DOODDO, no asume responsabilidad ante Usted y Usted libera de toda responsabilidad
                        a DOODDO por actos, omisiones o cualquier tipo de queja o insatisfacción
                        derivados de los servicios proporcionados por los proveedores directos de
                        servicios en el Sitio anunciados, en virtud de que DOODDO no tiene control
                        alguno o relación legal sobre su personal, equipo, operación o bienes. En
                        aquellas situaciones en que el Proveedor falle en la prestación de sus
                        servicios Usted podrá intentar cualquier recurso permitido por las leyes
                        aplicables en contra del Proveedor. DOODDO le garantiza que cuenta con altos
                        estándares de servicio y tomará un cuidado especial en la selección de los
                        Proveedores para evitar en todo momento la falla de los mismos.
                        Asimismo&nbsp;<a href={SITE_DOMAIN}><strong>DOODDO</strong><strong>&nbsp;</strong></a>no será
                        responsable y Usted libera de toda responsabilidad a DOODDO respecto de:
                        (a) La veracidad de las fotografías mostradas en su Sitio ya que las mismas son
                        solamente representativas y no garantizan que a su llegada, todo sea
                        exactamente igual como en ellas se aprecia y en el Sitio describe;&nbsp;
                        (b) La categoría de estrellas asignada a los hoteles y servicios, ya
                        que están basadas en la interpretación de&nbsp;<a href={SITE_DOMAIN}><strong>dooddo y de los Proveedores Directos de Servicios</strong><strong>&nbsp;</strong></a>&nbsp;y pueden diferir de las categorías reportadas en otros lugares;&nbsp;
                        (c) Las descripciones de los servicios de viaje son actualizadas por&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>&nbsp;a su mejor conocimiento, pero no garantizan que todo será exactamente
                        igual a su llegada;&nbsp;
                        (c) Cualquier tipo de falla por parte de Usted en obtener la documentación requerida
                        para su viaje tal como, pero no limitada a, pasaportes, visas, certificados
                        etc.&nbsp;
                        (d) Cualquier tipo de falla por parte de Usted para seguir las instrucciones de
                        viaje incluyendo, pero no limitado a, horarios de salida de aeropuerto, hora y
                        fecha de entrada y salida en hoteles, políticas de canje de cupones etc;&nbsp;
                        (e) Por falta de condiciones financieras por parte del Proveedor para
                        proporcionar el servicio.
                        (f) Respecto a los términos y condiciones y/o políticas de los Proveedores directos
                        de servicios.&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>no asumirá responsabilidad alguna y Usted
                        libera de toda responsabilidad a dooddo, así como de toda reclamación, costo,
                        gasto o pérdida que Usted pudiera sufrir incluyendo cualquier herida personal o
                        de terceras personas, accidentes o deceso, daños a pertenencias personales,
                        pérdida de diversión, enojo, desilusión, angustia o frustración, ya sea mental
                        o física, siempre que sean resultado de:
                        I.- Cualquier falla o falta por parte del Proveedor directo al proporcionar el
                        servicio.
                        II.- Cualquier falla o falta por parte de Usted al momento de disfrutar de los
                        servicios contratados.
                        III.- Cualquier falla o falta de usted al observar o cumplir los términos y
                        condiciones, políticas, instrucciones, recomendaciones, medias de seguridad,
                        etc de los Proveedores finales del servicio.
                        II.- Casos de fuerza&nbsp;&nbsp;mayor o casos fortuitos como lo
                        son: huelgas, atrasos, terremotos, conflictos bélicos, huracanes, nevadas, etc.
                        (que no le sean imputables a&nbsp; Best Day),
                        III.- Actos u omisiones de cualquiera otra persona que no sea&nbsp;&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>
                        IV.- Enfermedad, robo, disputas laborales, fallas mecánicas, cuarentena,
                        acciones gubernamentales, clima, o cualquier otra causa ajena al control
                        directo de&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>
                        V.- Ante la insistencia de Usted para realizar cualquier tipo de viaje,
                        actividad turística, tomar un tour, excursión, tomar cualquier tipo de
                        transportación aérea, terrestre, marítima y de cualquier tipo, bajo condiciones
                        precarias de salud o que no sean optimas para el desarrollo de tales
                        actividades, así como el desarrollo por Usted de cualquier tipo de deportes
                        extremos o actividades que puedan implicar algún peligro y que fueron
                        realizados bajo su entero conocimiento, o cuando padezca algún tipo de
                        enfermedad, condición médica, incapacidad, algún tipo de alergia, se encuentre
                        bajo medicación o prescripción médica, indicaciones de reposo y/o todas
                        aquellas condiciones médicas que bajo su propio riesgo fueron omitidas y que
                        pudieran resultar en un deterioro a su salud al momento de realizar dichas
                        actividades bajo consciencia de causa, bajo dichas ocasiones siempre se
                        considerarán fueron realizadas bajo su propio riesgo, liberando Usted dooddo&nbsp;respecto de cualquier denuncia,
                        demanda, queja, inconformidad, recompensa, indemnización, compensación,
                        cortesía, etc., derivados de cualquier incidente ocasionado durante el
                        desarrollo de cualquier servicio o actividad y cualquier evento futuro derivado
                        del incidente inicial por cualquiera de estas causas.
                        VI.- Cualquier otro evento que no se encuentre bajo el control directo de
                        dooddo. Cualquier reclamación o notificación por escrito en contra de&nbsp;<a href={SITE_DOMAIN}><strong>dooddo,</strong><strong>&nbsp;</strong></a>deberá
                        ser recibida a más tardar con catorce (14) días después del regreso de su
                        viaje; dooddo se reserva el derecho de rechazar o realizar una operación comercial
                        con algún Usuario- Turista en cualquier momento;&nbsp;dooddo se
                        reserva el derecho de cancelar o cambiar los servicios de viaje a nuestra
                        discreción, pero se tratará de sustituirlos con servicios comparables;&nbsp;Usted será responsable de verificar que el Proveedor final cuente
                        con servicios especiales como puede ser acceso, comodidades y servicios para
                        personas con capacidades diferentes, físicas o de cualquier otro tipo, lo cual
                        se le sugiere lo haga previamente a la realización de su reservación. Usted
                        será responsable de leer y de sujetarse a los términos y condiciones y/o
                        políticas de los Proveedores finales de servicios.
                        El reembolso total no será procedente en situaciones en las que el viaje tenga
                        que ser cancelado, interrumpido y/o pospuesto por&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>&nbsp;por razones que
                        estén fuera de su control (causas de fuerza mayor, tales
                        como, pero no limitadas a, clima, huracán, terremotos, actos de terrorismo,
                        etc.) y en los que las obligaciones contractuales de&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>&nbsp;con sus
                        proveedores no le permitan obtener reembolso de la suma pagada o a ser pagada
                        al Proveedor por cuenta del cliente. En cualquier caso, a discreción de&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>,
                        se podrá retener un 10% del total de la cantidad pagada por la reservación como
                        gasto administrativo. A pesar de la estrecha comunicación establecida por&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>&nbsp;con los Proveedores aquí anunciados, existe la posibilidad
                        de que la vigencia de algunos precios haya expirado o provenga de información
                        erróneamente proporcionada por dichos Proveedores con la que nuestra base de
                        datos es alimentada. No obstante esto,&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>&nbsp;toma la
                        precaución de verificar las tarifas que aplican con cada
                        reservación. En caso de que el precio correcto sea menor a la
                        cantidad cotizada,&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>&nbsp;aplicará el monto
                        menor. En caso de que el precio correcto sea mayor a la cantidad cotizada, su
                        Asesor de Viajes asignado se pondrá oportunamente en contacto con Usted,
                        informándole la cantidad correcta o bien registrando la cancelación de la
                        reservación a petición suya por no estar de acuerdo con el incremento en
                        precio, deslindando expresamente a&nbsp;<a href={SITE_DOMAIN}><strong>dooddo</strong><strong>&nbsp;</strong></a>&nbsp;y sus
                        Proveedores de cualquier responsabilidad o pago de indemnización por
                        inconvenientes causados por dicha cancelación. En el caso de que los servicios
                        contratados no pudieran ser proporcionados parcial o totalmente por parte del
                        Proveedor,&nbsp;dooddo solo podrá en determinadas
                        ocasiones gestionar por cuenta de Usted el reembolso del importe que proceda,
                        quedando relevada dooddo de cualquier responsabilidad o compromiso mayor en los
                        casos en que el reembolso por la cantidad total o restante no sea reembolsada
                        por el Proveedor.
                        &#8211;<strong>Deslinde
                            &#8220;U.S. Citizens&#8221;/Cuba</strong>&#8211;&nbsp;Si usted es
                        ciudadano de los Estados Unidos de América (EUA), o una compañía controlada o
                        bajo la titularidad de una entidad de EUA, y por lo tanto le resulta aplicable
                        el programa administrado por The Cuban Assets Control Regulations
                        (&#8220;CACR&#8221;), y bajo la regla 31 C.F.R. § 515, o le es prohibido adquirir
                        servicios turísticos hacia Cuba. En caso de adquirir cualquier servicio
                        turístico hacia Cuba de los ofertados en el presente sitio, usted manifiesta y
                        será responsable de contar con todos los requisitos previstos en dicha regla
                        y/o cualesquier ley, norma o regulación aplicable que para tal efecto le
                        determine EUA y/o el Department of Treasury&#8217;s Office of Foreign Assets Controls
                        (&#8220;OFAC&#8221;).</p>



                    <p><strong>TERMINACI</strong><strong>Ó</strong><strong>N.-</strong><strong>&nbsp;</strong>Dooddo podrá
                        dar por terminado estos Términos y Condiciones y/o cualquiera de sus servicios
                        en cualquier momento sin necesidad de avisar o notificar dicha terminación, por
                        cualquier causa o motivo, incluyendo por cualquier uso inapropiado de este
                        sitio o su incumplimiento con estos Términos y Condiciones. No obstante tal
                        terminación no afectará ningún derecho u obligación contraída por dooddo ante
                        Usted una vez confirmada una reservación.</p>



                    <p><strong>CESI</strong><strong>Ó</strong><strong>N.-</strong><strong>&nbsp;</strong>Usted no podrá
                        ceder, subcontratar, o delegar sus derechos, obligaciones o deberes aquí
                        contraídos.</p>



                    <p><strong>DIVISIBILIDAD.-</strong><strong>&nbsp;</strong>Estos Términos
                        y Condiciones continuarán siendo divisibles. En caso de que cualquier término o
                        Condición sea considerada prohibida o inejecutable, tal disposición no obstante
                        será obligatoria y tal determinación no afectará la validez y obligatoriedad de
                        cualquiera otra disposición restante.</p>



                    <p><strong>TITULOS.-</strong><strong>&nbsp;</strong>Los títulos
                        usados en los Términos y Condiciones son sólo para por su conveniencia y no
                        limitarán o afectarán de otra manera el contenido de los Términos y Condiciones
                        aquí establecidos.</p>



                    <p><strong>ACUERDO TOTAL.-</strong><strong>&nbsp;</strong>Los presentes
                        Términos y Condiciones, que aquí se incluyen, o a los cuales se haga
                        referencia, constituyen el acuerdo total y completo entre las partes en
                        relación con el objeto aquí establecido, y sustituye cualquier acuerdo o
                        contrato anterior efectuado entre las partes (ya sea oral o escrito) que esté
                        relacionado con el mismo objeto, y no será enmendado o modificado excepto si se
                        hace por escrito o haciendo que tales enmiendas o modificaciones estén
                        disponibles en este sitio.</p>



                    <p><strong>LEGISLACI</strong><strong>Ó</strong><strong>N APLICABLE.-</strong><strong>&nbsp;</strong>Estos Términos
                        y Condiciones se regirán por las leyes de México. Usted manifiesta que conoce
                        los alcances del presente Contrato, cuyos Términos y Condiciones son la
                        expresión fiel de su voluntad y se someten para el caso de incumplimiento o
                        interpretación a la competencia de la Procuraduría Federal del Consumidor, en
                        la vía administrativa, como instancia conciliatoria, para resolver las
                        diferencias que pudieran suscitarse y en caso de subsistir las mismas Usted
                        está de acuerdo en someterse a la competencia de las Leyes y Tribunales de
                        Ciudad de México denominada CDMX , bajo su jurisdicción exclusiva, renunciando
                        a su vez a cualquier otra jurisdicción que en razón de sus domicilios presentes
                        o futuros o por la ubicación de sus bienes o por su nacionalidad pudiera
                        corresponderle. En la medida que lo permita la ley de aplicación, ninguna
                        queja, reclamo o causa de acción que esté relacionado con su acceso o uso de
                        este Sitio deberá presentarse posterior al término transcurrido de un (1) año siguiente a
                        la fecha en que tal queja, reclamo o acción se haya presentado o la compra haya
                        sido completada.</p>



                    <p>Municipales
                        etc., que resulten necesarias para realizar su viaje, liberando a DOODDO sobre
                        cualquier problema que llegare a surgir con dichas autoridades. En los casos de
                        viajes internacionales Usted se compromete a presentarse en los aeropuertos y
                        documentarse ante las aerolíneas mínimo con&nbsp;TRES HORAS DE
                        ANTICIPACION en vuelos internacionales&nbsp; y DOS HORAS DE
                        ANTICIPACION en vuelos nacionales, salvo la instrucción expresa y por escrito que reciba
                        de&nbsp;DOODDO y/o alguna otra instrucción para presentarse aún
                        antes por parte dichas Autoridades.</p>



                    <p>En caso de
                        reservaciones hoteleras, Usted se compromete a firmar a la llegada al establecimiento
                        hotelero reservado, el contrato del servicio o la tarjeta de registro
                        correspondiente proporcionado por dicho establecimiento.</p>

                    <p><strong>NOTIFICACIONES</strong><strong>.-</strong><strong>&nbsp;</strong>Al proporcionar tu número de teléfono móvil y firmar de contrato, confirmas que eres titular autorizado del numero o que tienes el consentimiento del titular para recibir mensajes de nuestra parte. Puedes recibir mensajes de texto periódicos relacionados con noticias, actualizaciones, alertas, promociones, cobranza y otra información relevante sobre el estado de tu viaje. La frecuencia de los mensajes puede variar.</p>
                </div>
            </Contain>
            <Footer/>
        </div>
    )
}
export default TerminosCondiciones;