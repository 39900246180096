import {useEffect, useState} from "react";
import { getDestinos } from "../services/categoriaPaqueteService";
export const useDestinos = () =>{
    const [destinos, setDestinos] = useState([]);
    useEffect(() =>{
        Promise.all([getDestinos()]).then(Response => {
            setDestinos(Response[0]);
        }).catch(Error => {
            setDestinos();
        })
        
        return () =>{
            setDestinos([]);
        }
    },[])
    return [destinos];
}