export const CatLada = [
    {
      "name": "ANDORA",
      "lada": "+376"
    },
    {
      "name": "GABON",
      "lada": "+241"
    },
    {
      "name": "PANAMA",
      "lada": "+507"
    },
    {
      "name": "ANGOLA ",
      "lada": "+244"
    },
    {
      "name": "GAMBIA",
      "lada": "+220"
    },
    {
      "name": "PAPAU",
      "lada": "+675"
    },
    {
      "name": "ANGUILLA",
      "lada": "+1+264"
    },
    {
      "name": "GEORGIA",
      "lada": "+995"
    },
    {
      "name": "PARAGUAY ",
      "lada": "+595"
    },
    {
      "name": "ANTARTIDA",
      "lada": "+672"
    },
    {
      "name": "GHANA",
      "lada": "+233"
    },
    {
      "name": "PERU",
      "lada": "+51"
    },
    {
      "name": "ANTIGUA Y BARBUDA ",
      "lada": "+1+268"
    },
    {
      "name": "GRANADA",
      "lada": "+1+809"
    },
    {
      "name": "POLONIA",
      "lada": "+48"
    },
    {
      "name": "ARABIA SAUDITA ",
      "lada": "+966"
    },
    {
      "name": "GRECIA",
      "lada": "+30"
    },
    {
      "name": "PORTUGAL",
      "lada": "+351"
    },
    {
      "name": "ARGELIA",
      "lada": "+213"
    },
    {
      "name": "GROENLANDIA",
      "lada": "+299"
    },
    {
      "name": "PUERTO RICO ",
      "lada": "+1+787"
    },
    {
      "name": "ARGENTINA",
      "lada": "+54"
    },
    {
      "name": "GUADALUPE",
      "lada": "+590"
    },
    {
      "name": "QATAR",
      "lada": "+974"
    },
    {
      "name": "ARMENIA",
      "lada": "+374"
    },
    {
      "name": "GUAM",
      "lada": "+671"
    },
    {
      "name": "REINO UNIDO ",
      "lada": "+44"
    },
    {
      "name": "ARUBA ISLA ",
      "lada": "+297+8"
    },
    {
      "name": "GUATEMALA ",
      "lada": "+502"
    },
    {
      "name": "REUNION",
      "lada": "+262"
    },
    {
      "name": "ASCENCION",
      "lada": "+247"
    },
    {
      "name": "GUAYANA FR",
      "lada": "+594"
    },
    {
      "name": "RUMANIA",
      "lada": "+40"
    },
    {
      "name": "AUSTRALIA",
      "lada": "+61"
    },
    {
      "name": "GUINEA BISSAU ",
      "lada": "+245"
    },
    {
      "name": "RUSIA",
      "lada": "+7"
    },
    {
      "name": "AUSTRIA",
      "lada": "+43"
    },
    {
      "name": "GUINEA REP",
      "lada": "+224"
    },
    {
      "name": "RWANDA",
      "lada": "+40"
    },
    {
      "name": "AZERBAIYAN",
      "lada": "+994"
    },
    {
      "name": "GUYANA",
      "lada": "+592"
    },
    {
      "name": "SABA ISLA ",
      "lada": "+599+4"
    },
    {
      "name": "BAHAMAS",
      "lada": "+1+242"
    },
    {
      "name": "HONDURAS",
      "lada": "+504"
    },
    {
      "name": "SALOMON ISLAS ",
      "lada": "+677"
    },
    {
      "name": "BAHREIN",
      "lada": "+973"
    },
    {
      "name": "HONG KONG ",
      "lada": "+852"
    },
    {
      "name": "SAMOA AMERIACANA ",
      "lada": "+684"
    },
    {
      "name": "BANGLADESH",
      "lada": "+880"
    },
    {
      "name": "HUNGARIA",
      "lada": "+36"
    },
    {
      "name": "SAMOA OCC.",
      "lada": "+685"
    },
    {
      "name": "BARBADOS",
      "lada": "+1+246"
    },
    {
      "name": "INDIA REP",
      "lada": "+91"
    },
    {
      "name": "SAN EUSTAQUIO ",
      "lada": "+599+3"
    },
    {
      "name": "BELARUS",
      "lada": "+375"
    },
    {
      "name": "INDONESIA",
      "lada": "+62"
    },
    {
      "name": "SAN KITTS ",
      "lada": "+1+869"
    },
    {
      "name": "BELGICA",
      "lada": "+32"
    },
    {
      "name": "IRAN",
      "lada": "+98"
    },
    {
      "name": "SAN MAARTEN ",
      "lada": "+599+5"
    },
    {
      "name": "BELICE",
      "lada": "+501"
    },
    {
      "name": "IRAK",
      "lada": "+964"
    },
    {
      "name": "SAN MARINO ",
      "lada": "+378"
    },
    {
      "name": "BENIN",
      "lada": "+229"
    },
    {
      "name": "IRLANDA",
      "lada": "+353"
    },
    {
      "name": "SAN PEDRO Y MIGUELON ",
      "lada": "+508"
    },
    {
      "name": "BERMUDAS",
      "lada": "+1+441"
    },
    {
      "name": "ISLANDIA",
      "lada": "+354"
    },
    {
      "name": "SAN VICENTE Y GRANADINAS ",
      "lada": "+1+809"
    },
    {
      "name": "BHUTAN",
      "lada": "+975"
    },
    {
      "name": "ISRAEL",
      "lada": "+972"
    },
    {
      "name": "SANTA ELENA ISLA ",
      "lada": "+290"
    },
    {
      "name": "BOLIVIA",
      "lada": "+591"
    },
    {
      "name": "ITALIA",
      "lada": "+39"
    },
    {
      "name": "SANTA LUCIA ",
      "lada": "+1+758"
    },
    {
      "name": "BONAIRE",
      "lada": "+599+7"
    },
    {
      "name": "JAMAICA",
      "lada": "+1+876"
    },
    {
      "name": "SANTO TOME Y PRINCIPE REP ",
      "lada": "+239"
    },
    {
      "name": "BOSNIA HERZEGOVINA ",
      "lada": "+387"
    },
    {
      "name": "JAPON",
      "lada": "+81"
    },
    {
      "name": "SENEGAL",
      "lada": "+221"
    },
    {
      "name": "BOTSWANA",
      "lada": "+267"
    },
    {
      "name": "JORDANIA",
      "lada": "+962"
    },
    {
      "name": "SEYCHELLES",
      "lada": "+248"
    },
    {
      "name": "BRASIL",
      "lada": "+55"
    },
    {
      "name": "KENYA",
      "lada": "+264"
    },
    {
      "name": "SIERRA LEONA ",
      "lada": "+232"
    },
    {
      "name": "BRUNEI DARUSSALAM ",
      "lada": "+673"
    },
    {
      "name": "LIBANO",
      "lada": "+961"
    },
    {
      "name": "SINGAPUR",
      "lada": "+65"
    },
    {
      "name": "BULGARIA",
      "lada": "+359"
    },
    {
      "name": "LIBERIA ",
      "lada": "+231"
    },
    {
      "name": "SIRIA REP. ",
      "lada": "+963"
    },
    {
      "name": "BURKINA FASSO ",
      "lada": "+226"
    },
    {
      "name": "LIBIA",
      "lada": "+218"
    },
    {
      "name": "SOMALIA",
      "lada": "+252"
    },
    {
      "name": "BURUNDI",
      "lada": "+257"
    },
    {
      "name": "LITUANIA",
      "lada": "+370"
    },
    {
      "name": "SRI LANKA ",
      "lada": "+94"
    },
    {
      "name": "CABO VERDE ",
      "lada": "+238"
    },
    {
      "name": "LUXEMBURGO",
      "lada": "+352"
    },
    {
      "name": "SUDAFRICA",
      "lada": "+27"
    },
    {
      "name": "CAIMAN ISLAS ",
      "lada": "+1+345"
    },
    {
      "name": "MACAO",
      "lada": "+853"
    },
    {
      "name": "SUDAN",
      "lada": "+249"
    },
    {
      "name": "CAMBOYA",
      "lada": "+855"
    },
    {
      "name": "MACEDONIA",
      "lada": "+389"
    },
    {
      "name": "SUECIA",
      "lada": "+46"
    },
    {
      "name": "CAMERUN",
      "lada": "+237"
    },
    {
      "name": "MADAGASCAR",
      "lada": "+261"
    },
    {
      "name": "SUIZA",
      "lada": "+41"
    },
    {
      "name": "COLOMBIA",
      "lada": "+57"
    },
    {
      "name": "MALASIA",
      "lada": "+60"
    },
    {
      "name": "SURINAME",
      "lada": "+597"
    },
    {
      "name": "CONGO",
      "lada": "+242"
    },
    {
      "name": "MALAWI",
      "lada": "+265"
    },
    {
      "name": "SWAZILIANDIA",
      "lada": "+268"
    },
    {
      "name": "COOK ISLAS ",
      "lada": "+682"
    },
    {
      "name": "MALDIVAS",
      "lada": "+960"
    },
    {
      "name": "TAHITI",
      "lada": "+689"
    },
    {
      "name": "COREA SUR ",
      "lada": "+82"
    },
    {
      "name": "MALI",
      "lada": "+223"
    },
    {
      "name": "TAILANDIA",
      "lada": "+66"
    },
    {
      "name": "COREA NORTE ",
      "lada": "+850"
    },
    {
      "name": "MALTA",
      "lada": "+356"
    },
    {
      "name": "TAIWAN",
      "lada": "+886"
    },
    {
      "name": "COSTA DE MARFIL ",
      "lada": "+255"
    },
    {
      "name": "MALVINAS ISLAS ",
      "lada": "+500"
    },
    {
      "name": "TANZANIA",
      "lada": "+255"
    },
    {
      "name": "COSTA RICA ",
      "lada": "+506"
    },
    {
      "name": "MARIANAS ISLAS ",
      "lada": "+670"
    },
    {
      "name": "TONGA",
      "lada": "+676"
    },
    {
      "name": "CROACIA",
      "lada": "+385"
    },
    {
      "name": "MARRUECOS",
      "lada": "+212"
    },
    {
      "name": "TONGOLESA",
      "lada": "+228"
    },
    {
      "name": "CUBA",
      "lada": "+53"
    },
    {
      "name": "MARSHALL",
      "lada": "+692"
    },
    {
      "name": "TRINIDAD Y TOBAGO ",
      "lada": "+1+868"
    },
    {
      "name": "CURAZAO",
      "lada": "+599+9"
    },
    {
      "name": "MARTINICA",
      "lada": "+596"
    },
    {
      "name": "TUNEZ",
      "lada": "+216"
    },
    {
      "name": "CHECA REPUBLICA ",
      "lada": "+420"
    },
    {
      "name": "MAURICIO",
      "lada": "+230"
    },
    {
      "name": "TURCAS",
      "lada": "+1+649"
    },
    {
      "name": "CHILE",
      "lada": "+56"
    },
    {
      "name": "MAURITANIA REP",
      "lada": "+222"
    },
    {
      "name": "TURKMENISTAN",
      "lada": "+993"
    },
    {
      "name": "CHINA",
      "lada": "+86"
    },
    {
      "name": "MEXICO",
      "lada": "+52"
    },
    {
      "name": "TURQUIA",
      "lada": "+90"
    },
    {
      "name": "CHIPRE",
      "lada": "+357"
    },
    {
      "name": "MICRONESIA",
      "lada": "+691"
    },
    {
      "name": "TUVALU",
      "lada": "+688"
    },
    {
      "name": "DIEGO GARCIA ",
      "lada": "+246"
    },
    {
      "name": "MOLDOVA",
      "lada": "+373"
    },
    {
      "name": "UCRANIA",
      "lada": "+380"
    },
    {
      "name": "DINAMARCA",
      "lada": "+45"
    },
    {
      "name": "MONACO",
      "lada": "+377"
    },
    {
      "name": "UGANDA",
      "lada": "+256"
    },
    {
      "name": "DJIBOUTI",
      "lada": "+253"
    },
    {
      "name": "MONGOLIA",
      "lada": "+976"
    },
    {
      "name": "URUGUAY",
      "lada": "+598"
    },
    {
      "name": "DOMINICANA COM. DE ",
      "lada": "+1+767"
    },
    {
      "name": "MONTSERRAT",
      "lada": "+1+664"
    },
    {
      "name": "VANUATU",
      "lada": "+678"
    },
    {
      "name": "DOMINICANA REP. DE ",
      "lada": "+1+809"
    },
    {
      "name": "MOZAMBIQUE",
      "lada": "+258"
    },
    {
      "name": "VENEZUELA",
      "lada": "+58"
    },
    {
      "name": "ECUADOR ",
      "lada": "+593"
    },
    {
      "name": "MYANMAR",
      "lada": "+95"
    },
    {
      "name": "VIETNAM",
      "lada": "+84"
    },
    {
      "name": "EGIPTO",
      "lada": "+20"
    },
    {
      "name": "NAURU",
      "lada": "+674"
    },
    {
      "name": "VIRGENES Is. (E.U.A)",
      "lada": "+1+340"
    },
    {
      "name": "EL SALVADOR ",
      "lada": "+503"
    },
    {
      "name": "NEPAL",
      "lada": "+977"
    },
    {
      "name": "VIRGENES BRITANICAS ISLAS ",
      "lada": "+1+284"
    },
    {
      "name": "EMIRATOS ARABES ",
      "lada": "+971"
    },
    {
      "name": "NEVIS",
      "lada": "+1+869"
    },
    {
      "name": "YEMEN",
      "lada": "+967"
    },
    {
      "name": "ERITREA",
      "lada": "+291"
    },
    {
      "name": "NICARAGUA",
      "lada": "+505"
    },
    {
      "name": "YUGOSLAVIA",
      "lada": "+381"
    },
    {
      "name": "ESLOVACA REP",
      "lada": "+421"
    },
    {
      "name": "NIGER",
      "lada": "+227"
    },
    {
      "name": "ZAIRE",
      "lada": "+243"
    },
    {
      "name": "ESLOVENIA ",
      "lada": "+386"
    },
    {
      "name": "NIGERIA",
      "lada": "+234"
    },
    {
      "name": "ZAMBIA",
      "lada": "+260"
    },
    {
      "name": "ESPA\u00d1A",
      "lada": "+34"
    },
    {
      "name": "NIUE",
      "lada": "+683"
    },
    {
      "name": "ZIMBABWE",
      "lada": "+263"
    },
    {
      "name": "ESTONIA ",
      "lada": "+372"
    },
    {
      "name": "NORUEGA ",
      "lada": "+47"
    },
    {
      "name": "ETIOPIA",
      "lada": "+251"
    },
    {
      "name": "NUEVA CALEDONIA ",
      "lada": "+687"
    },
    {
      "name": "FEROES ISLAS ",
      "lada": "+298"
    },
    {
      "name": "NUEVA ZELANDIA ",
      "lada": "+64"
    },
    {
      "name": "FIJI",
      "lada": "+679"
    },
    {
      "name": "OMAN SULTANIA ",
      "lada": "+968"
    },
    {
      "name": "FILIPINAS",
      "lada": "+63"
    },
    {
      "name": "PAISES BAJOS ",
      "lada": "+31"
    }
  ]