import axios from "axios";
import { APIURL } from "./../config/siteConfig";
import { getCabecera } from "./../utils/getCabecera";
const urlCategory = "Category";

export const getDestinos = async () => {
  return await axios.get(
    `${APIURL}/${urlCategory}/find`,{
        headers: getCabecera(),
    }
  ).then((Response) => {
    return Response.data;
  }).catch((Error =>{
    return [];
  }))
};

export const getDestino = async ({idDestino}) => {
  return await axios.get(
    `${APIURL}/${urlCategory}/${idDestino}`,{
        headers: getCabecera(),
    }
  ).then((Response) => {
    return Response.data;
  }).catch((Error =>{
    return {};
  }))
};
