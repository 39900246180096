import "./index.css";
import Contain from "../../global/Contain";
import SECTUR from "./../../assets/SECTUR-DOODDO.webp";
import Title from "../../global/Title";
import Header from "../../global/Header";
import Footer from "../../global/Footer";
const Sectur = () => {
    return (
        <div>
            <Header />
            <Contain>
                <Title titulo="SECTUR" />
                <div className="Sectur-main">
                    <img src={SECTUR} alt="SECTUR" />
                </div>
            </Contain>
            <Footer/>
        </div>
    )
}
export default Sectur;