import axios from "axios";
import { APIURL } from "./../config/siteConfig";
import { getCabecera } from "../utils/getCabecera";

const urlSupplier = "Supplier";

export const getDestinos = async () => {
  return await axios.get(
    `${APIURL}/${urlSupplier}/groupPack/find`,
  ).then((Response) => {
    return Response.data;
  }).catch((Error =>{
    return [];
  }))
};

export const getProveedorPaquete = async ({ClavePaquete}) => {
  return await axios.get(
    `${APIURL}/${urlSupplier}/flight/${ClavePaquete}`,
    {
      headers: getCabecera(),
    }
  ).then((Response) => {
    return Response.data;
  }).catch((Error =>{
    return [];
  }))
};
