import { animated } from '@react-spring/web'
import styles from "./index.module.css"
import { useAnimationElmentoFadeRigth } from '../hooks/useAnimationElmentoFadeRigth'
import { useAnimationElmentoZoomIn } from '../hooks/useAnimationElmentoZoomIn'
const Ventajas = () => {
    const [refTitulo, propsTitulo] = useAnimationElmentoFadeRigth()
    const [refTexto1, propsTexto1] = useAnimationElmentoZoomIn()
    const [refTexto2, propsTexto2] = useAnimationElmentoZoomIn()
    const [refTexto3, propsTexto3] = useAnimationElmentoZoomIn()
    const [refTexto4, propsTexto4] = useAnimationElmentoZoomIn()
    return (
        <div className={styles.container}>
            <animated.div ref={refTitulo} style={propsTitulo}>
                <h3 className={styles.title}>VENTAJAS DE VIAJAR EN GRUPO</h3>
            </animated.div>
            <animated.div ref={refTexto1} style={propsTexto1}>
                <div className={styles.box}>
                    <p className={styles.subTitle}><span>1</span> Precios más bajos</p>
                    <p>Nosotros establecemos convenios directos con las aerolineas, conocidos como bloqueos, lo cual garantiza un mejor precio al consumidor.</p>
                </div>
            </animated.div>
            <animated.div ref={refTexto2} style={propsTexto2}>
                <div className={styles.box}>
                    <p className={styles.subTitle}><span>2</span> Seguridad:</p>
                    <p>Llevamos más de 10 años trabajando con nuestros proveedores (hoteles, aerolíneas, guías, choferes, etc.), lo cual garantiza que disfrutes de cada servicio contratado.</p>
                </div>
            </animated.div>
            <animated.div ref={refTexto3} style={propsTexto3}>
                <div className={styles.box}>
                    <p className={styles.subTitle}><span>3</span> Gastos compartidos:</p>
                    <p>¿Sabías que las personas a menudo no tienen en cuenta sus traslados durante el viaje (Uber, taxis, autobuses turísticos), los cuales suelen ser muy costosos? Con los viajes en grupo, esto ya está incluido.</p>
                </div>
            </animated.div>
            <animated.div ref={refTexto4} style={propsTexto4}>
                <div className={styles.box}>
                    <p className={styles.subTitle}><span>4</span> Socialización:</p>
                    <p>Conoce personas con los mismos intereses que tú, haz nuevos amigos y explora el mundo.</p>
                </div>
            </animated.div>
        </div>

    )
}
export default Ventajas