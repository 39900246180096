import {useEffect, useState} from "react"
import { getTipoCambio } from "../services/catalogoService"
export const useTipoCambio = () =>{
    const [tipoCambio, setTipoCambio] = useState({});
    useEffect(() =>{
        Promise.all([getTipoCambio()]).then(Response => {
            setTipoCambio(Response[0]);
        }).catch(Error => {
            setTipoCambio({});
        })
        return () =>{
            setTipoCambio({});
        }
    },[])
    return {tipoCambio};
}