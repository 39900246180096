import { Accordion } from "react-bootstrap"
import "./index.css";
import Title from "../../global/Title"
import Header from "../../global/Header"
import Footer from "../../global/Footer"
import DestinosMenu from "../../components/DestinosMenu"
import SubTitle from "../../global/SubTitleAlternativo"
import elReforma from "../../assets/medios/Dooddo_elreforma.webp"
import imagen from "../../assets/medios/Dooddo_Imagenradio.webp"
import mexicoBus from "../../assets/medios/Dooddo_MexicoBusiness-.webp"
import viajes from "../../assets/medios/Dooddo_vaijes.webp"
import { SITE_DOMAIN } from "../../config/siteConfig"
import Flujo from "./../../assets/Pasos-PlandePagos.svg"
import Cel from "./../../assets/Cel3.png"
import chek from "./../../assets/Check.svg"
import Comentario from "../../components/Comentario"
import Cliente1 from "./../../assets/comentarios/cliente1.png"
import Cliente2 from "./../../assets/comentarios/cliente2.png"
import Cliente3 from "./../../assets/comentarios/cliente3.png"
import Cliente4 from "./../../assets/comentarios/cliente4.png"
import Cliente5 from "./../../assets/comentarios/cliente5.png"
const Conocenos = () => {
    return (
        <div>
            <Header />
            <div className="Conocenos-container-div">
                <div className="Conocenos-container-header">
                    <div className="title">
                        <Title titulo="conócenos" isWhite={true} />
                    </div>
                    <div className="menu">
                        <DestinosMenu />
                    </div>
                </div>
                <div className="Conocenos-video">
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/lc06U30HVqI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="Conocenos-container-dicen">
                <SubTitle titulo="Lo que dicen los medios de nosotros" />
                <div className="dicen-container">
                    <div className="dicen-item">
                        <img src={elReforma} alt="El reforma" />
                        <a href="https://www.reforma.com/aplicacioneslibre/preacceso/articulo/default.aspx?__rval=1&urlredirect=https://www.reforma.com/viajes-a-largo-plazo-opcion-al-reactivar-turismo/ar1957004?v=1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <h3>Viajes a largo plazo, opción al reactivar turismo</h3>
                        </a>
                    </div>
                    <div className="dicen-item">
                        <img src={imagen} alt="Imagen" />
                        <a href="https://www.imagenradio.com.mx/viaja-cualquier-parte-del-mundo-mediante-la-innovacion-financiera"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <h3>Viaja a cualquier parte del mundo mediante la innovación financiera</h3>
                        </a>
                    </div>
                    <div className="dicen-item">
                        <img src={mexicoBus} alt="Mexico" />
                        <a href="https://mexicobusiness.news/tech/news/fintechs-key-reactivating-tourism-mexico"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <h3>Fintechs Key to Reactivating Tourism in Mexico</h3>
                        </a>
                    </div>
                    <div className="dicen-item">
                        <img src={viajes} alt="Maleta de viajes" />
                        <a href="https://maletadeviajes.com/conoce-dooddo-la-fintech-mexicana-enfocada-en-el-turismo/emprendedores-viajeros/?fbclid=IwAR1iYTWg4cygYOr_dApkEy39YPkRysL-DV8pnTqn6ROlZbZavQqyRljscwg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <h3>Conoce Dooddo, la fintech mexicana enfocada en el turismo</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div className="Conocenos-container-preguntas">
                <SubTitle titulo="Preguntas frecuentes" isWhite={true} />
                <div className="preguntas-container">
                    <div className="preguntas">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>¿Cómo poder solicitar información sobre cancelación o cambios en mi plan?</Accordion.Header>
                                <Accordion.Body>
                                Por favor para cualquier duda o comentario escribe un correo a <a href="mailto:te_escuchamos@dooddo.com ">te_escuchamos@dooddo.com</a> (Explicar específicamente cual es la situación a tratar).
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>¿Qué es Dooddo?</Accordion.Header>
                                <Accordion.Body>
                                    Dooddo es la primera plataforma digital que permite a sus usuarios organizar viajes a través de pequeños pagos mensuales, teniendo excelentes beneficios como: mejores precios al vender por volumen, elimina comisiones bancarias, busca ofertas, atención personalizada, excelentes convenios a nivel mundial.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>¿Están registrados ante la secretaria de Turismo?</Accordion.Header>
                                <Accordion.Body>
                                    Sí, nuestro número es: 04090151376 y la puedes solicitar a tu agente o descargar en nuestro footer.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>¿Dooddo emite facturas?</Accordion.Header>
                                <Accordion.Body>
                                    Sí, tanto a personas físicas como morales una vez que se adquieren sus servicios contratados. De igual forma puedes descargar tu estado de cuenta mensual en nuestra plataforma, donde podrás visualizar tus abonos y compras.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>¿Cómo inicio mi ahorro?</Accordion.Header>
                                <Accordion.Body>
                                    Una vez que nos proporciones tus datos en el botón de “SOLICITAR INFORMACIÓN” un agente se comunicará contigo para conocer más a fondo tus requerimientos con respecto al viaje. Luego de tu llamada, tu agente te proporcionará una cotización y de ser aceptada, obtendrás un usuario y una contraseña para ingresar a nuestro sistema y comenzar a disfrutar de todos los beneficios de ahorrar con Dooddo. En nuestra plataforma podrás realizar tus aportaciones mensuales, descargar estados de cuenta y checar el avance de tu ahorro.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>¿Mi dinero está seguro?</Accordion.Header>
                                <Accordion.Body>
                                    Tu dinero se encuentra en una cuenta de BBVA siempre disponible y visible, con una referencia a tu nombre, se firma un contrato de prestación de servicios donde se especificarán los vuelos, tours, hospedaje y amenidades contratadas.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>¿Qué pasa si no puedo realizar mi viaje?</Accordion.Header>
                                <Accordion.Body>
                                    ¡Descuida! Puedes cancelar tu plan de ahorro en el momento en que tú lo desees. Los gastos de cambio de nombre en aerolíneas corren por cuenta del cliente y Dooddo solo cobrara un 10% por cargo de sus servicios realizados.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>¿Dónde están ubicados?</Accordion.Header>
                                <Accordion.Body>
                                    Estamos ubicados en la Ciudad de México, aun así, atendemos a nivel nacional gracias a la tecnología que hoy nos permite acortar distancias y así poderte ofrecer mejores precios reduciendo nuestros gastos operativos.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>¿Existe algún beneficio si es un viaje de varias personas?</Accordion.Header>
                                <Accordion.Body>
                                    Sí, a partir de 10 personas el líder del grupo u organizador podrá optar por excelentes beneficios. Si tienes un ahorro grupal como el mencionado, solicítale a tu agente que te platique los beneficios.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>¿Por qué mi cotización es en dólares/euros/pesos?</Accordion.Header>
                                <Accordion.Body>
                                    Esto se debe a que tu ahorro será cotizado tomando como referente al proveedor del viaje que estas adquiriendo, aun así, tu depósito en la plataforma será en pesos mexicanos.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="vermas">
                        <a href={`${SITE_DOMAIN}preguntasFrecuentes`} rel="noopener noreferrer">
                            {"Leer más >"}
                        </a>
                    </div>
                </div>
            </div>
            <div className="Conocenos-container-pagos">
                <div className="flujo">
                    <img src={Flujo} alt="Flujo de pago" />
                </div>
                <div className="titulo">
                    <SubTitle titulo="Como iniciar mi plan de pagos" />
                </div>
                <div className="contenido">
                    <div className="pago-Check">
                        <img src={chek} alt="check" />
                        <h3> AHORRA EN NUESTRA PLATAFORMA DIGITAL</h3>
                    </div>
                    <div className="pasos">
                        <p>En ella podrás</p>
                        <p><span>1.</span> Comenzar el ahorror para tu proximo viaje.</p>
                        <p><span>2.</span> Hacer grupos de viaje y visualizar el avance de cada miembro.</p>
                        <p><span>3.</span> Recibir las mejores ofertas de vuelos y hospedaje.</p>
                        <p><span>4.</span> Asesoría en visas y documentos a cualquier destino.</p>
                        <p><span>5.</span> Compras de forma segura.</p>
                        <p><span>6.</span> Ahorrar en pesos, euros o dólares para encontrar mejores ofertas.</p>
                    </div>
                </div>
                <div className="Movil">
                    <img src={Cel} alt="Flujo de pago" />
                </div>
            </div>
            <div className="Conocenos-container-comentarios">
                <SubTitle titulo="Comentarios de clientes" isWhite={true} />
                <div className="Comentarios-Container">
                    <Comentario
                        index={1}
                        estrellas={5}
                        src={Cliente1}
                        Nombre="Nayla Dayamin"
                        Descripcion="Contrate un servicio de curso de Verano en Madrid con ellos, y aproveche para hacer un mini tour por Europa, su plataforma de ahorro muy amigable."
                    />
                    <Comentario
                        index={2}
                        src={Cliente3}
                        Nombre="Tania Arévalo"
                        Descripcion="Buen servicio todo en tiempo y forma. La plataforma muy amigable y mi agente siempre respondió mis dudas."
                    />
                    <Comentario
                        index={3}
                        src={Cliente4}
                        Nombre="Miriam Rodriguez"
                        Descripcion="Me encanta viajar y con Dooddo tengo muchas opciones en destinos. La plataforma para ahorrar es muy sencilla y además puedo ahorrar junto a mis amigas."
                    />
                    <Comentario
                        index={4}
                        src={Cliente5}
                        Nombre="Itza Nayelli"
                        Descripcion="Dooddo nos permitió ahorrar en su plataforma para planear nuestro viaje familiar. Muy buenos precios y atención personalizada de sus agentes. Gran agencia de viajes."
                    />
                    <Comentario
                        index={5}
                        src={Cliente2}
                        Nombre="Alex ArizaMorrison"
                        Descripcion="Excelente servicio, ahorré en su plataforma para un viaje a Costa Rica de aniversario y todo se entregó en tiempo y forma, ahora estamos ahorrando para Europa."
                    />
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default Conocenos;