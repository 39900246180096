import { getPaqueteLangin } from "../services/paqueteService";
const dataInicial = {
    clavePaquete: "0",
    paquetesLanding: [],
    titulo: "",
    idPaquete: 0,
    paquete: {}
}
const TARGET_CLAVE_PAQUETE = "TARGET_CLAVE_PAQUETE";
const GET_PAQUETES_LANDING_ACTION = "GET_PAQUETES_LANDING_ACTION"
const TARGET_PAQUETE_ACTION = "TARGET_PAQUETE_ACTION"
export default function sliderReducer(state = dataInicial, action) {
    switch (action.type) {
        case GET_PAQUETES_LANDING_ACTION:
            return { ...state, paquetesLanding: action.payload.paquetesLanding, clavePaquete: action.payload.clavePaquete, titulo: action.payload.titulo, paquete: action.payload.paquete, idPaquete: action.payload.idPaquete }
        case TARGET_PAQUETE_ACTION:
            return { ...state, paquete: action.payload.paquete }
        //! Eliminar
        case TARGET_CLAVE_PAQUETE:
            return { ...state, clavePaquete: action.payload.clavePaquete, titulo: action.payload.titulo, idPaquete: action.payload.idPaquete };
        default:
            return state;
    }
}
export const targetPaqueteAction = ({ paquete }) => (dispatch) => {
    dispatch({
        type: TARGET_PAQUETE_ACTION,
        payload: {
            paquete
        }
    })
}
export const targetClavePaqueteAction = ({ clavePaquete, titulo, idPaquete }) => (dispatch) => {
    dispatch({
        type: TARGET_CLAVE_PAQUETE,
        payload: {
            clavePaquete: clavePaquete,
            titulo,
            idPaquete
        }
    })
}
export const obtenerPaquetesLandingAction = ({ IdLanding }) => async (dispatch) => {
    return await getPaqueteLangin({ IdLanding }).then(Response => {
        dispatch({
            type: GET_PAQUETES_LANDING_ACTION,
            payload: {
                paquetesLanding: Response,
                clavePaquete: Response[0].ClavePaquete,
                titulo: Response[0].Nombre,
                paquete: Response[0],
                idPaquete: Response[0].IdPaquete,
            }
        })
        return {status: 200}
    }).catch(Error => {
        return Error
    })
}