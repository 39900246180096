import { formatDinero } from "./../../utils/getDinero"
import "./Extras.css"
const Extras = (props) => {
    const {Nombre="", Suplemento=0, Descripcion="", Moneda=""} = props
    return (
        <div className="ExtrasComponentContainer">
            <h5>{Nombre} </h5>
            <div dangerouslySetInnerHTML={{ __html: `${Descripcion}` }} />
            <h5 className="ExtraComponentPrice"><span>{formatDinero(Suplemento)} {Moneda}</span></h5>
        </div>
    )
}
export default Extras;