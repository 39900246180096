import './index.css'
import { useNavigate } from 'react-router-dom';
const DestinoCard = (props) => {
    const { id=0, titulo = "", src = "", redirict = false, onClick = () => {} } = props;
    const navigate = useNavigate()
    const onAction = () => {
        navigate(`/paquetes/${id}/${titulo}`, { state: { id: id, src: src } })
    }
    return (
        <div className='DestinoContainer' onClick={(redirict) ? onClick : onAction}>
            <div className='DestinoCard'>
                <img src={src} alt={titulo} />
                <h2>{titulo}</h2>
            </div>
        </div>
    )
}
export default DestinoCard;