import "./index.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
const SubTitleAlternativo = (props) => {
    AOS.init();
    const { titulo = "", isWhite=false } = props
    return (
        <div className={isWhite ? "main-SubTitulo-alternative white" : "main-SubTitulo-alternative"}  data-aos='fade-down'>
            <h2>{titulo}</h2>
        </div>
    )
}
export default SubTitleAlternativo;