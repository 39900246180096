import axios from "axios";
import { APIURL } from "./../config/siteConfig";
import { getCabecera } from "../utils/getCabecera";

const urlPackage = "PackageDetail";

export const getDetallePaquete = async ({idPaquete}) => {
  return await axios.get(
    `${APIURL}/${urlPackage}/${idPaquete}`,
    {
      headers: getCabecera(),
    }
  ).then((Response) =>{
    return Response.data
  })
};