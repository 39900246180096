import { useEffect, useState } from "react";
import { getHotelesPaquete } from "../services/hotelService";
export const useHoteles = ({ idPaquete }) => {
    const [hoteles, setHoteles] = useState([]);
    useEffect(() => {
        Promise.all([getHotelesPaquete({ idPaquete })]).then(Response => {
            setHoteles(Response[0]);
        }).catch(Error => {
            setHoteles([]);
        })
        return () => {
            setHoteles([]);
        }
    }, [idPaquete])
    return [hoteles];
}