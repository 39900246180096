import {useEffect, useState} from "react";
import { getDetallePaquete } from "../services/paqueteDetalleService";
export const useDetallePaquete = ({idPaquete}) =>{
    const [detalle, setDetalle] = useState({})
    const [load, setLoad] = useState(false)
    const [error, setError] = useState(false)
    useEffect(() =>{
        setLoad(true)
        Promise.all([getDetallePaquete({idPaquete})]).then(Response => {
            setDetalle(Response[0])
            setError(false)
        }).catch(Error => {
            setError(true)
            setDetalle({});
        }).finally(() => {
            setLoad(false)
        })
        return () =>{
            setDetalle({});
        }
    },[idPaquete])
    return [detalle, load, error];
}