import Contain from "../../global/Contain"
import classes from "./index.module.css"
import { useParams } from "react-router"
import LogoDoodo from "./../../assets/Logo_Dooddo_Ahorro2.png"
import { useCotizacion } from "../../hooks/useCotizacion"
import { formatDineroCentavos } from "./../../utils/getDinero"
import { FormatoFecha, formatSubDaysIso } from "./../../utils/getFecha"
import { useMensualidades } from "../../hooks/useMensualidades"
export function MiCotizacion() {
    const { idCotizacion, token } = useParams()
    const [cotizacion, load] = useCotizacion({ idCotizacion })
    console.log("🚀 ~ MiCotizacion ~ cotizacion:", cotizacion)
    const [mensualidades, loadMensualidad] = useMensualidades({ fechaSalida: FormatoFecha({ date: cotizacion?.FechaSalida, formato: "MMddyyyy" }), Monto: cotizacion.MontoPaquete, fechaRegistro: cotizacion?.FechaRegistro, listaPagos: cotizacion?.lFormatoPago })
    const ObtenerSuplemento = ({ busqueda }) => {
        const plaza = cotizacion?.lContizacionSuplemento.find(plaza => plaza.Descripcion === busqueda)
        if (plaza) {
            return plaza.Precio
        }
        return 0
    }
    if (!load && cotizacion?.IdCotizacion <= 0)
        return <div>
            <Contain>
                <div className={classes.content}>
                    <h1>No se encontro cotización</h1>
                </div>
            </Contain>
        </div>
    if (!load && cotizacion?.IdCotizacion > 0)
        return (
            <div>
                <Contain>
                    <div className={classes.main}>
                        <div className={classes.content}>
                            <img className={classes.logo} src={LogoDoodo} alt="Dooddo" />
                        </div>
                        <div className={classes.content}>
                            <h1>Cotización</h1>
                        </div>
                        <div className={classes.grid2}>
                            <div className={classes.content}>
                                <div className={classes.textLeft}>
                                    <p>{`${cotizacion.Cliente?.Nombre}`}</p>
                                    <p>{`${cotizacion.Cliente?.EMail}`}</p>
                                    <p>{`${cotizacion.Cliente?.Telefono}`}</p>
                                </div>
                            </div>
                            <div className={classes.content}>
                                <div className={classes.textLeft}>
                                    <h5 className={classes.bold}>{`Dooddo viajes`}</h5>
                                    <p>{`Rio lerma 94 piso 3`}</p>
                                    <p>{`AVI160825MVA`}</p>
                                    <p>{`Cdmx, Ciudad de México 06500`}</p>
                                    <p>{`Preparado por: ${cotizacion.Agente?.Nombre} ${cotizacion.Agente?.Apellido_Paterno}`}</p>
                                    <p>{`${cotizacion.Agente?.eMail}`}</p>
                                    <p>{`${cotizacion.Agente?.Telefono_Contacto}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.divider}>
                            <h2 className={classes.title}>COTIZACIÓN</h2>
                        </div>
                        <div className={classes.content}>
                            <table className={classes.tabla}>
                                <thead>
                                    <tr>
                                        <th style={{ width: "40%" }}>PAQUETE {`${cotizacion.NombrePaquete}`}</th>
                                        <th className={classes.alter}>CANTIDAD</th>
                                        <th className={`${classes.alter} ${classes.noBorder}`}>PRECIO TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                style={{ textTransform: "capitalize" }}
                                                className={classes.textLeft}
                                                dangerouslySetInnerHTML={{ __html: `${cotizacion.EnvioPaqueteProveedor?.Descripcion}` }}
                                            />
                                        </td>
                                        <td>
                                            {(cotizacion.Adultos > 0) ? `${cotizacion.Adultos} Adultos` : ''}
                                            <br />
                                            {(cotizacion.Menores > 0) ? `${cotizacion.Menores} Menores` : ''}
                                            <br />
                                            {(cotizacion.Infantes > 0) ? `${cotizacion.Infantes} Infantes` : ''}
                                        </td>
                                        <td className={classes.noBorder}>
                                            {(cotizacion.Adultos > 0) ? `${formatDineroCentavos(cotizacion.EnvioPaqueteProveedor?.Precio * cotizacion.Adultos)} ${cotizacion.Moneda?.Clave}` : ''}
                                            <br />
                                            {(cotizacion.Menores > 0) ? `${formatDineroCentavos(ObtenerSuplemento({ busqueda: "Menor e infante" }))} ${cotizacion.Moneda?.Clave}` : ''}
                                            <br />
                                            {(cotizacion.Infantes > 0) ? `${formatDineroCentavos(ObtenerSuplemento({ busqueda: "Infante" }))} ${cotizacion.Moneda?.Clave}` : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 className={classes.title}>SALIDA</h5>
                                            <p>{FormatoFecha({ date: cotizacion?.FechaSalida, formato: "dd/MM/yyyy" }).toUpperCase()}</p>
                                        </td>
                                        <td>
                                            {`${cotizacion?.NumeroPersona}`}
                                        </td>
                                        <td className={classes.noBorder}>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'SALIDA' && item.Precio > -1)
                                                        return `${formatDineroCentavos(item?.Precio * item.Cantidad)} ${cotizacion.Moneda?.Clave}`
                                                    if (item.TipoSuplemento === 'SALIDA' && item.Precio < -1)
                                                        return <br />
                                                })
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 className={classes.title}>TIPO DE PLAZA</h5>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'PLAZA' && !item.Descripcion.includes("infante") && !item.Descripcion.includes("Infante"))
                                                        return <p key={index}>{`Habitación / ${item.Descripcion}`}</p>
                                                })
                                            }
                                        </td>
                                        <td>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'PLAZA' && !item.Descripcion.includes("infante") && !item.Descripcion.includes("Infante"))
                                                        return <p key={index}>{`${item.Cantidad}`}</p>
                                                })
                                            }
                                        </td>
                                        <td className={classes.noBorder}>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'PLAZA' && !item.Descripcion.includes("infante") && !item.Descripcion.includes("Infante") && item.Precio > 0)
                                                        return <p key={index}>{`${formatDineroCentavos(item.Precio)} ${cotizacion.Moneda?.Clave}`}</p>
                                                    if (item.TipoSuplemento === 'PLAZA' && !item.Descripcion.includes("infante") && !item.Descripcion.includes("Infante") && item.Precio === 0)
                                                        return <br />
                                                })
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 className={classes.title}>EXTRAS</h5>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'EXTRA')
                                                        return <p key={index}>{`EXTRA / ${item.Descripcion}`}</p>
                                                })
                                            }

                                        </td>
                                        <td>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'EXTRA')
                                                        return <p key={index}>{`${item.Cantidad}`}</p>
                                                })
                                            }
                                        </td>
                                        <td className={classes.noBorder}>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'EXTRA' && item.Precio > 0)
                                                        return <p key={index}>{`${formatDineroCentavos(item.Precio)} ${cotizacion.Moneda?.Clave}`}</p>
                                                    if (item.TipoSuplemento === 'EXTRA' && item.Precio <= 0)
                                                        return <br />

                                                })
                                            }

                                        </td>
                                    </tr>
                                    {
                                        (cotizacion?.MontoInteres > 0) &&
                                        <tr>
                                            <td>
                                                <h5 className={classes.title}> MESES DIFERIDOS</h5>
                                                <p>{`Meses Diferidos`}</p>
                                            </td>
                                            <td>
                                            </td>
                                            <td className={classes.noBorder}>
                                                {`${formatDineroCentavos(cotizacion?.MontoInteres)} ${cotizacion.Moneda?.Clave}`}
                                            </td>
                                        </tr>
                                    }

                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <h5 className={classes.title}>TOTAL</h5>
                                        </td>
                                        <td className={classes.noBorder}>
                                            {`${formatDineroCentavos(cotizacion?.MontoPaquete)} ${cotizacion.Moneda?.Clave}`}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={classes.divider}>
                            <h2 className={classes.title}>PLAN DE PAGOS PARA TU VIAJE {`${cotizacion.NombrePaquete}`}</h2>
                        </div>
                        {
                            (cotizacion?.lFormatoPago.length > 0) &&
                            <div>
                                <div className={classes.content}>
                                    <table className={classes.tabla}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "20%" }}># DE PAGO</th>
                                                <th className={classes.alter}>FECHA LÍMITE DE PAGO</th>
                                                <th className={classes.alter}>CANTIADAD</th>
                                                <th className={`${classes.alter} ${classes.noBorder}`}>MÉTODO PAGO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cotizacion?.lFormatoPago.map(pago => {
                                                    if (pago?.CatTipoPago?.IdCatTipoPago === 1) {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className={classes.noBorder} rowspan={(!loadMensualidad) ? mensualidades.length + 1 : 0}>
                                                                        <img style={{ width: "100%", height: "100%", maxWidth: "60px" }} src="https://assets.dooddo.com/bank/1.png" alt="plazsos" />
                                                                        <img style={{ width: "100%", height: "100%", maxWidth: "60px" }} src="https://assets.dooddo.com/bank/0.1.png" alt="plazsos" />
                                                                        <br />
                                                                        <p>Debito / Practicaja</p>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    (!loadMensualidad) &&
                                                                    mensualidades.map((pago, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{pago?.NumeroPago}</td>
                                                                                <td>{FormatoFecha({ date: pago?.FechaCorte, formato: "dd/MM/yyyy" }).toUpperCase()}</td>
                                                                                <td>{`${formatDineroCentavos(pago?.MontoMensualidad)} ${cotizacion.Moneda?.Clave}`}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    if (pago?.CatTipoPago?.IdCatTipoPago === 2) {
                                                        const monto = cotizacion?.MontoPaquete
                                                        const tasa = pago?.BancoFormatoPago?.SobreTasa + pago?.BancoFormatoPago?.TasaGeneral
                                                        const porcentaje = tasa / 100
                                                        const interes = monto * porcentaje
                                                        const montoInteres = monto + interes
                                                        const montoTotal = (pago?.PorcentajePago * montoInteres) / 100
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td rowspan="2" className={classes.noBorder}>
                                                                        {/* <img style={{ width: "100%", height: "auto", maxWidth: "100px" }} src={`https://assets.dooddo.com/bank/${pago?.BancoFormatoPago?.Banco?.IdBanco}.png`} alt="plazsos" /> */}
                                                                        <img style={{ width: "100%", height: "100%", maxWidth: "40px" }} src="https://assets.dooddo.com/bank/86.png" alt="plazsos" />
                                                                        <img style={{ width: "100%", height: "100%", maxWidth: "60px" }} src="https://assets.dooddo.com/bank/87.png" alt="plazsos" />
                                                                        <img style={{ width: "100%", height: "100%", maxWidth: "60px" }} src="https://assets.dooddo.com/bank/logo_ae.png" alt="plazsos" />
                                                                        <p>Tarjeta de crédito</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        {
                                                                            (!loadMensualidad) &&
                                                                                (mensualidades.length > 0) ? mensualidades.length + 1 : 1
                                                                        }
                                                                    </td>
                                                                    <td>{`${formatSubDaysIso({ date: cotizacion?.FechaSalida, days: 60, formato: "dd/MM/yyyy" }).toUpperCase()}`}</td>
                                                                    <td >
                                                                        <p>{`${formatDineroCentavos(montoTotal)} ${cotizacion.Moneda?.Clave}`}</p>
                                                                        <h7>Podrás diferir este pago a {pago?.NumeroPagos} meses.</h7>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </Contain>
            </div>
        )

    return <div>
        <Contain>
            <div className={classes.content}>
                <h1>Cargando...</h1>
            </div>
        </Contain>
    </div>
}
