import {useEffect, useState} from "react";
import { getCotizacion } from "../services/cotizacionService";
export const useCotizacion = ({idCotizacion}) =>{
    const [cotizacion, setCotizacion] = useState({});
    const [load, setLoad] = useState(true)
    useEffect(() =>{
        setLoad(true)
        Promise.all([getCotizacion({idCotizacion})]).then(Response => {
            setLoad(false)
            setCotizacion(Response[0])
        }).catch(Error => {
            setLoad(false)
            setCotizacion({})
        })
        return () =>{
            setCotizacion({});
        }
    },[idCotizacion])
    return [cotizacion, load];
}