import "./index.css"
import Contain from "../../global/Contain"
import Title from "../../global/Title"
import SubTitle from "../../global/SubTitle"
import Destinos from "../../components/DestinoCard"
import Header from "./../../global/Header"
import Footer from "../../global/Footer"
import CarrucelBanner from "./../../components/CarrucelBanner"
import { useDestinos } from "./../../hooks/useDestinos"
import { APIURL } from "../../config/siteConfig"
// import Ofertas from "./../../assets/destinos/promo.png"
// import { useNavigate } from "react-router-dom"

const DestinosPage = () => {
    const [destinos] = useDestinos()
    // const navigate = useNavigate()
    // const navigateOofertas = () => [
    //     navigate("/paquetes/oferta")
    // ]
    return (
        <div>
            <Header />
            <Contain>
                <SubTitle titulo="Paquetes por destino" />
                <div className="Destino-Paquetes-main">
                    {/* <Destinos
                        redirict={true}
                        onClick={navigateOofertas}
                        src={Ofertas}
                        titulo="OFERTAS"
                    /> */}
                    {
                        destinos.map((destino => (
                            <Destinos
                                key={destino.IdCategoriaPaquete}
                                id={destino.IdCategoriaPaquete}
                                src={`${APIURL}/Category/${destino.IdCategoriaPaquete}/Logo`}
                                titulo={destino.Nombre}
                            />
                        )))
                    }

                </div>
            </Contain>
            <div className="Destino-Paquetes-main-content">
                <Title titulo="Paquetes más vendidos" />
            </div>
            <CarrucelBanner />
            <Footer/>
        </div>
    )
}
export default DestinosPage;