import {useEffect, useState} from "react"
import { getSalidasByPaqueteId } from "../services/salidaService"
export const useSalidas = ({idPaquete}) =>{
    const [salidas, setSalidas] = useState([]);
    const [load, setLoad] = useState(true)
    useEffect(() =>{
        setLoad(true)
        Promise.all([getSalidasByPaqueteId({idPaquete})]).then(Response => {
            setSalidas(Response[0]);
        }).catch(Error => {
            setSalidas([]);
        }).finally(() =>{
            setLoad(false)
        })
        return () =>{
            setSalidas([]);
            setLoad(true)
        }
    },[idPaquete])
    return [salidas, load];
}