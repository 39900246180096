import "./Pais.css"
import Bandera from "./../../assets/paises/italia.png"
import { ASSETS_URL } from "../../config/siteConfig"
const Pais = (props) => {
    const { Paises = [] } = props
    return (
        <div className="ContainerPaises">
            {
                Paises.map((pais, index) =>
                    <div key={index} className="PaisesContainer" >
                        <img src={`${ASSETS_URL}flags/${pais.Codigo.trim()}.png`} alt={pais.Nombre} />
                        <div>
                        <h5>{pais.Nombre}</h5>
                        <div className="CiudadesContainer">
                            {
                                pais.Ciudades.map((ciudad, index) =>
                                    <p key={index}>
                                        {ciudad.Nombre}
                                    </p>)
                            }
                        </div>
                        </div>
 
                    </div>
                )
            }

        </div>
    )
}
export default Pais