import "./Movil.css";
import Contain from "../../global/Contain";
import Title from "../../global/Title";
import { Accordion } from "react-bootstrap";
import { SITE_DOMAIN } from "../../config/siteConfig"
const PreguntasFrecuentesMovil = () => {
    return (
        <div>
            <Contain>
                <div className="preguntasFrecuentes-main">
                    <Title titulo="Preguntas Frecuentes" />
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>¿Cómo puedo saber el estatus de mi viaje?</Accordion.Header>
                            <Accordion.Body>
                                Tu viaje cuenta con 4 estatus
                                <br />
                                <br />
                                “TE RECOMENDAMOS IR SIEMPRE AL CORRIENTE EN TUS PAGOS YA QUE ES NECESARIO PARA PODER CUMPLIR CON LOS SIGUIENTES PASOS”
                                <br />
                                <br />
                                <b>PRE RESERVA:</b> Significa que ya eres cliente DOODDO y que aun NO cuentas con los fondos suficientes para que te confirme una fecha de salida.
                                <br />
                                <br />
                                <b>RESERVA:</b> Significa que tu fecha de salida está confirmada y que el grupo de salida está confirmado.
                                <br />
                                <br />
                                <b>AÉREOS:</b> Significa que la Aerolínea ya tiene la información de tu pasaporte (para este punto necesitamos que en la sección CHECK IN, ya estén todos tus datos) de no estarlos la aerolínea podría aplicar penalizaciones.
                                <br />
                                <br />
                                <b>LIQUIDADO:</b> Significa que tu viaje esta listo se ha liquidado a todos los proveedores de tu viaje y en este punto puedes solicitar tu factura viaje, así mismo recuerda que 5 dias antes de la salida se envían los documentos de viaje a todo el grupo y los puedes descargar en la SECCIÓN WALLET en la APP DOODDO
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>¿Cómo funciona un viaje en grupo?</Accordion.Header>
                            <Accordion.Body>
                                Los viajes en grupos en un modelo muy usado en todo el mundo, se utiliza para abaratar costos, conocer personas, y no tener que preocuparte por nada en tu viaje.
                                <br />
                                <br />
                                La agencia hace convenios con aerolíneas para solicitar “bloqueos” o grupos de viaje, es decir apartar cierta cantidad de asientos en un vuelo, una vez que se tiene el convenio se busca un operador terrestre confiable y seguro, su trabajo es brindar todo lo que incluye el plan del viajero en el destino, así la agencia puede garantizar un excelente servicio en destino.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>¿Qué incluye mi viaje?</Accordion.Header>
                            <Accordion.Body>
                                Lo podrás revisar en tu APP en la sección detalles de mi viaje
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>¿Por qué no me marcan por teléfono diario si ya les di mi dinero?</Accordion.Header>
                            <Accordion.Body>
                                Dooddo es una empresa internacional con miles de clientes, desafortunadamente no contamos con el personal suficiente para estar marcando diario para saludarte, lo que sí hacemos es enviarte notificaciones de lo que se necesita y los estatus de tu viaje, así como recordatorios de pago.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>¿Qué es Dooddo?</Accordion.Header>
                            <Accordion.Body>
                                Dooddo es la primera plataforma digital que permite a sus usuarios organizar viajes a través de pequeños pagos mensuales, teniendo excelentes beneficios como: mejores precios al vender por volumen, elimina comisiones bancarias, busca ofertas, atención personalizada, excelentes convenios a nivel mundial.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>¿Están registrados ante la secretaria de Turismo?</Accordion.Header>
                            <Accordion.Body>
                                Sí, nuestro número es: 04090151376 y la puedes solicitar a tu agente o descargar en nuestro footer.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>¿Doodoo emite facturas?</Accordion.Header>
                            <Accordion.Body>
                                Si, en la sección ayuda podrás emitir tus facturas, solo recuerda que esto solo se puede solicitar estando en el estatus de liquidado, esto se debe a que en cualquier otro estatus puedes solicitar la cancelación de tu viaje
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>¿Cuando se entregan los documentos de viaje?</Accordion.Header>
                            <Accordion.Body>
                                Los documentos se entregan 5 días antes del viaje, esto es debido a que los grupos de viaje se suelen cerrar 25 días antes de la salida, asimismo la aerolínea nos envía documentos 8 días antes de la salida y el proveedor terrestre de igual forma ya con los hoteles confirmados.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>¿Mi dinero está seguro?</Accordion.Header>
                            <Accordion.Body>
                                Tu dinero se encuentra en una cuenta de BBVA siempre disponible y visible, con una referencia a tu nombre, se firma un contrato de prestación de servicios donde se especificarán los vuelos, tours, hospedaje y amenidades contratadas.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>¿Qué pasa si no puedo realizar mi viaje?</Accordion.Header>
                            <Accordion.Body>
                                Lo primero que tienes que tener claro son los estatus de tu viaje, ya que en cada uno de ello pagarás el monto dado al proveedor final (aerolínea o tour operador), aparte de ello se cobrara una comision por cancelacion del 15% + IVA del monto total de viaje
                                <br />
                                Si por razones no puedes realizar tu viaje en un status de AÉREO O LIQUIDADO, podríamos solicitar a la AEROLÍNEA una cotización por cambio de nombre.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>¿Dónde están ubicados?</Accordion.Header>
                            <Accordion.Body>
                                Estamos ubicados en la Ciudad de México, aun así, atendemos a nivel nacional gracias a la tecnología que hoy nos permite acortar distancias y así poderte ofrecer mejores precios reduciendo nuestros gastos operativos.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="11">
                            <Accordion.Header>¿Por qué mi cotización es en dólares/euros/pesos?</Accordion.Header>
                            <Accordion.Body>
                                Esto se debe a que tu ahorro será cotizado tomando como referente al proveedor del viaje que estas adquiriendo, aun así, tu depósito en la plataforma será en pesos mexicanos.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="12">
                            <Accordion.Header>¿Cómo puedo depositar a mi cuenta de ahorro?</Accordion.Header>
                            <Accordion.Body>
                                Una vez que se formalice tu contrato y seas cliente Dooddo, recibirás tu talón de aportaciones. Podrás depositar en ventanillas BBVA, practicajas BBVA, app BBVA, mediante nuestra plataforma con cualquier tarjeta de débito o crédito VISA, MASTERCARD o AMEX.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="13">
                            <Accordion.Header>¿Cómo puedo eliminar mi cuenta de usuario?</Accordion.Header>
                            <Accordion.Body>
                                <p>En Dooddo nos preocupamos por la confidencialidad de nuestros usuarios por lo que ningún dato o información personal es compartido, para mayor información consulta nuestro <a href={`${SITE_DOMAIN}aviso-de-privacidad`}>Aviso de privacidad</a>.</p>
                                <p>El eliminar tu cuenta implica que ya no tendrás acceso a nuestra estra plataforma web o app móvil, ya no será posible consultar depósitos y estados de cuenta al igual que toda la documentación relacionada a tu viaje, como boletos de avión, cupones, seguros, pases de tours o pases de transporte terrestre, al igual que ya no podrás efectuar cambios en tu viaje como Cambios de fecha de salida, Cambio de destino o paquete, Cambios en la cantidad de pasajeros en tu viaje, Cambios de nombre o Cancelaciones.</p>
                                <p>Para efectuar la eliminación de tu cuenta es necesario enviar un correo electrónico a <a href="mailto:atencion@dooddo.com">atencion@dooddo.com</a> solicitando una cancelación de cuenta, agregando nombre completo y número de referencia. Este proceso puede tardar hasta 48 hrs en efectuarse y se notificará por medio de tu cuenta de correo electrónico la confirmación de eliminación de cuenta.</p>
                            </Accordion.Body>
                        </Accordion.Item>
{/* 



                        <Accordion.Item eventKey="0">
                            <Accordion.Header>¿Cómo poder solicitar información sobre cancelación o cambios en mi plan?</Accordion.Header>
                            <Accordion.Body>
                                Por favor para cualquier duda o comentario escribe un correo a <a href="mailto:te_escuchamos@dooddo.com ">te_escuchamos@dooddo.com</a> (Explicar específicamente cual es la situación a tratar).
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>¿Dooddo emite facturas?</Accordion.Header>
                            <Accordion.Body>
                                Sí, tanto a personas físicas como morales una vez que se adquieren sus servicios contratados. De igual forma puedes descargar tu estado de cuenta mensual en nuestra plataforma, donde podrás visualizar tus abonos y compras.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>¿Cómo inicio mi ahorro?</Accordion.Header>
                            <Accordion.Body>
                                Una vez que nos proporciones tus datos en el botón de “SOLICITAR INFORMACIÓN” un agente se comunicará contigo para conocer más a fondo tus requerimientos con respecto al viaje. Luego de tu llamada, tu agente te proporcionará una cotización y de ser aceptada, obtendrás un usuario y una contraseña para ingresar a nuestro sistema y comenzar a disfrutar de todos los beneficios de ahorrar con Dooddo. En nuestra plataforma podrás realizar tus aportaciones mensuales, descargar estados de cuenta y checar el avance de tu ahorro.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>¿Qué pasa si no puedo realizar mi viaje?</Accordion.Header>
                            <Accordion.Body>
                                ¡Descuida! Puedes cancelar tu plan de ahorro en el momento en que tú lo desees. Los gastos de cambio de nombre en aerolíneas corren por cuenta del cliente y Dooddo solo cobrara un 10% por cargo de sus servicios realizados.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>¿Existe algún beneficio si es un viaje de varias personas?</Accordion.Header>
                            <Accordion.Body>
                                Sí, a partir de 10 personas el líder del grupo u organizador podrá optar por excelentes beneficios. Si tienes un ahorro grupal como el mencionado, solicítale a tu agente que te platique los beneficios.
                            </Accordion.Body>
                        </Accordion.Item>
   */}


                    </Accordion>
                </div>
            </Contain>
        </div>
    )
}
export default PreguntasFrecuentesMovil;