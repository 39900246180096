import { useSpring } from '@react-spring/web'
import { useInView } from 'react-intersection-observer'
export const useAnimationElmentoZoomIn = () => {
    const [ref, inView] = useInView({
        triggerOnce: false, // Animación solo una vez cuando el objeto entra en el viewport
        threshold: 0, // Cambia según necesites, indica qué porcentaje del objeto debe estar visible
    })
    const props = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'scale(1)' : 'scale(0.5)',
    })
    return [ref, props]
}