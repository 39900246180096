import axios from "axios";
import { APIURL } from "./../config/siteConfig";
import { getCabecera } from "../utils/getCabecera";

const urlHotel = "Hotel";

export const getHotelesPaquete = async ({ idPaquete }) => {
    return await axios.get(
        `${APIURL}/${urlHotel}/Package/${idPaquete}`,
        {
            headers: getCabecera(),
        }
    ).then((Response) => {
        return Response.data
    })
};