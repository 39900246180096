import "./custom.css"
import Carousel from 'react-bootstrap/Carousel'
import styles from "./index.module.css"
import viajero1 from "./../../../assets/viajeros/1.jpg"
import viajero2 from "./../../../assets/viajeros/2.png"
import viajero3 from "./../../../assets/viajeros/3.png"
import viajero4 from "./../../../assets/viajeros/4.png"
import viajero5 from "./../../../assets/viajeros/5.png"
import viajero6 from "./../../../assets/viajeros/6.png"
import viajero7 from "./../../../assets/viajeros/7.png"
import viajero8 from "./../../../assets/viajeros/8.png"
import { animated } from '@react-spring/web'
import { useAnimationElmentoZoomIn } from '../hooks/useAnimationElmentoZoomIn'
const Viajeros = () => {
    const [ref, props] = useAnimationElmentoZoomIn()
    return (
        <animated.div ref={ref} style={props}>
            <Carousel controls={false} indicators={true} className="customCarrucelVIACAL">
                <Carousel.Item interval={null}>
                    <div className={styles.carrucelContainer}>
                        <img
                            style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                            src={viajero1}
                            alt={"Viajero 1"}
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={null}>
                    <div className={styles.carrucelContainer}>
                        <img
                            style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                            src={viajero2}
                            alt={"Viajero 2"}
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={null}>
                    <div className={styles.carrucelContainer}>
                        <img
                            style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                            src={viajero3}
                            alt={"Viajero 3"}
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={null}>
                    <div className={styles.carrucelContainer}>
                        <img
                            style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                            src={viajero4}
                            alt={"Viajero 4"}
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={null}>
                    <div className={styles.carrucelContainer}>
                        <img
                            style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                            src={viajero5}
                            alt={"Viajero 5"}
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={null}>
                    <div className={styles.carrucelContainer}>
                        <img
                            style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                            src={viajero6}
                            alt={"Viajero 6"}
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={null}>
                    <div className={styles.carrucelContainer}>
                        <img
                            style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                            src={viajero7}
                            alt={"Viajero 7"}
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={null}>
                    <div className={styles.carrucelContainer}>
                        <img
                            style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                            src={viajero8}
                            alt={"Viajero 8"}
                        />
                    </div>
                </Carousel.Item>
            </Carousel>

        </animated.div>
    )
}
export default Viajeros