import "./index.css"
import styles from "./index.module.css"
import Sol from "./../../assets/detalle/sol.png"
import Luna from "./../../assets/detalle/luna.png"
import Aero from "./../../assets/detalle/aero.png"
import { formatDinero } from "./../../utils/getDinero"
import { FormatoFecha } from './../../utils/getFecha'
import { useParams } from "react-router"
import { usePaqueteProveedor } from "../../hooks/usePaqueteProveedor"
import { APIURL } from "../../config/siteConfig"
const CardDetalle = (props) => {
    const { clavePaquete } = useParams()
    const { dias = "", noches = "", precio = 0, moneda = "", fechaSalida = "2021-12-12", precioDescuento = 0, precioNormal = 0 } = props
    const [paqueteProveedor] = usePaqueteProveedor({ ClavePaquete: clavePaquete })
    return (
        <div className={styles.cardPaqueteDetalle}>
            <div className={`${styles.cardPaqueteItem} ${styles.dias}`}>
                <div className={styles.flexCenter}>
                    <div className={styles.contenedorFlexCenter}>
                        <div className={styles.flexCenter}>
                            <img className="icon" src={Sol} alt="Sol" />
                            <h2>{dias}</h2>
                        </div>
                        <h6>Dias</h6>
                    </div>
                    <h2>+</h2>
                    <div className={styles.contenedorFlexCenter}>
                        <div className={styles.flexCenter}>
                            <img className="icon" src={Luna} alt="Luna" />
                            <h2>{noches}</h2>
                        </div>
                        <h6>Noches</h6>
                    </div>
                </div>
            </div>
            <div className={`${styles.cardPaqueteItem} ${styles.vuelos}`}>
                <div className={`${styles.flexCenter} ${styles.col}`}>
                    <h4>Volando con:</h4>
                    <div>
                        {
                            (paqueteProveedor.length === 0) &&
                            <img src={Aero} alt="AeroMexico" />
                        }
                        {
                            (paqueteProveedor.length > 0) &&
                            <img src={`${APIURL}/Supplier/${paqueteProveedor[0]?.proveedor?.IdProveedor}/Logo`} alt={paqueteProveedor[0]?.proveedor?.RazonSocial} />
                        }
                    </div>
                </div>
            </div>
            <div className={`${styles.cardPaqueteItem} ${styles.salidas}`}>
                <div className={`${styles.flexCenter} ${styles.col}`}>
                    <h4>Saliendo en:</h4>
                    <h2 style={{ textTransform: "capitalize", fontSize: "24px" }}>
                        {
                            (fechaSalida) &&
                            FormatoFecha({ date: fechaSalida, formato: 'MMMM yyyy' })
                        }
                    </h2>
                </div>
            </div>
            <div className={`${styles.cardPaqueteItem} ${styles.montos}`}>
                <div className={`${styles.flexCenter} ${styles.col}`}>
                    <h6>Mensualidades desde</h6>
                    <h2 style={{ textTransform: "capitalize", fontSize: "24px" }}>{formatDinero(precio)} {moneda}</h2>
                    <h6>*Revisa los suplementos</h6>
                </div>
            </div>
            <div className={`${styles.cardPaqueteItem} ${styles.precio}`}>
                <div className={`${styles.flexCenter} ${styles.col}`}>
                    <h6>Precio paquete</h6>
                    <h2 style={{ fontSize: "20px" }}>Promoción: {formatDinero(precioDescuento)} {moneda}</h2>
                    <h6 style={{ fontSize: "17px" }}>General: {formatDinero(precioNormal)} {moneda}</h6>
                </div>
            </div>
        </div>
    )
}
export default CardDetalle;