import "./index.css"
import Start from "./../../assets/Star.svg"
const Comentario = (props) => {
    const {Nombre ="", src="", Descripcion="", estrellas=5, index=0} = props

    const getSatart = () => {
        const arrayElemet = []
        for (let index = 0; index < estrellas; index++) {
            arrayElemet.push(index + 1)
        }
        return arrayElemet;
    }
 
    return (
        <div className="Coment-comentario">
            <img className={(index%2===0) ? "Coment-photo right" : "Coment-photo left"} src={src} alt={Nombre} />
            <div className={(index%2===0) ? "Coment-descripcion right" : "Coment-descripcion left"}>
                <h3>{Nombre}</h3>
                <div className="coment-start">
                    {
                        getSatart().map((element) =>
                            <img src={Start} alt="Estrella" />
                        )
                    }
                </div>
                <p>{Descripcion}</p>
            </div>
        </div>
    )
}
export default Comentario