import { animated } from '@react-spring/web'
import styles from "./index.module.css"
import { useAnimationElmentoFadeRigth } from '../hooks/useAnimationElmentoFadeRigth'
import { useAnimationElmentoZoomIn } from '../hooks/useAnimationElmentoZoomIn'
const PasoPaso = () => {
    const [refTitulo, propsTitulo] = useAnimationElmentoFadeRigth()
    const [refSubTitulo, propsSubTitulo] = useAnimationElmentoFadeRigth()
    const [refTexto1, propsTexto1] = useAnimationElmentoZoomIn()
    const [refTexto2, propsTexto2] = useAnimationElmentoZoomIn()
    const [refTexto3, propsTexto3] = useAnimationElmentoZoomIn()
    const [refTexto4, propsTexto4] = useAnimationElmentoZoomIn()
    const [refTexto5, propsTexto5] = useAnimationElmentoZoomIn()
    return (
        <div className={styles.container}>
            <animated.div ref={refSubTitulo} style={propsSubTitulo}>
                <h3 className={styles.titulo2}>CÓMO OBTENER TU PAQUETE</h3>
            </animated.div>
            <animated.div ref={refTitulo} style={propsTitulo}>
                <h3 className={styles.title}>PASO A PASO</h3>
            </animated.div>
            <animated.div ref={refTexto1} style={propsTexto1}>
                <div className={styles.box}>
                    <p className={styles.subTitle}><span>1</span>REGISTRO</p>
                    <p>Envíanos un whatsapp para que uno de nuestros para que uno de nuestros agentes te brinde una asesoría personalizada y mande una cotización.</p>
                </div>
            </animated.div>
            <animated.div ref={refTexto2} style={propsTexto2}>
                <div className={styles.box}>
                    <p className={styles.subTitle}><span>2</span> ACEPTACIÓN DE PLAN DE VIAJE:</p>
                    <p>Cuando se encuentre tu viaje ideal, podrás firmar tu contrato y usar tu APP MÓVIL, para así poder administrar tu viaje.</p>
                </div>
            </animated.div>
            <animated.div ref={refTexto3} style={propsTexto3}>
                <div className={styles.box}>
                    <p className={styles.subTitle}><span>3</span>ENTREGA DE DOCUMENTOS</p>
                    <p>Una vez liquidado tu plan de ahorro, podrás descargar desde la APP MÓVIL los documentos necesarios para obte ner tu viaje en grupo.</p>
                </div>
            </animated.div>
            <animated.div ref={refTexto4} style={propsTexto4}>
                <div className={styles.box}>
                    <p className={styles.subTitle}><span>4</span> VIAJA</p>
                    <p>Se reúnen en el aeropuerto para dirigirse al destino. Al llegar, los estará esperando el chófer y el guía para iniciar la aventura.</p>
                </div>
            </animated.div>
            <animated.div ref={refTexto5} style={propsTexto5}>
                <div className={styles.box}>
                    <p className={styles.subTitle}><span>5</span> DE REGRESO A CASA</p>
                    <p>Te garantizamos que tendrás una de las mejores experiencias de la vida, y por ello, una vez concluido tu viaje, te enviamos una encuesta para calificar nuestro servicio.</p>
                </div>
            </animated.div>
        </div>

    )
}
export default PasoPaso