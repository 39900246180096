import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Contain = ({children}) => {
    AOS.init();
    return (
        <div className="Contain-main" data-aos='fade'>
            {children}
        </div>
    )
}
export default Contain;