import "./index.css"
const MenuIcon = (props) => {
    const {active = false, onClick = () =>{}} = props; 
    return (
        <div className={(!active) ? `MenuIcon-container` : `MenuIcon-change `} onClick={onClick}>
            <div className="MenuIcon-bar1"></div>
            <div className="MenuIcon-bar2"></div>
            <div className="MenuIcon-bar3"></div>
        </div>
    )
}
export default MenuIcon;