import { useSpring, animated } from '@react-spring/web';
import styles from  './preload.module.css'

const Preload = ({ loading }) => {
  const stylesProps = useSpring({
    opacity: loading ? 1 : 0,
    pointerEvents: loading ? 'auto' : 'none',
  })

  return (
    <animated.div className={styles.preload} style={stylesProps}>
      <div className={styles.spinner}></div>
    </animated.div>
  )
}

export default Preload;