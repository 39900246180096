import "./index.css"

const Boton = (props) => {
    const { children, onClick = () => { }, href = "", link = false, target="" } = props;
    if (link) return (
        <a className="BotonDooddo" href={href} target={target} >
            {children}
        </a>
    )
    return (
        <button className="BotonDooddo" onClick={onClick} >
            {children}
        </button>
    )
}
export default Boton;