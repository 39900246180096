import { useSpring, animated } from '@react-spring/web'
import styles from "./index.module.css"

const FloatingCircle = ({ size, delay, x, y, color, background, blur, zindex }) => {
  const props = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(1.2)' },
    config: {tension: 170, friction: 26},
    loop: { reverse: true },
    delay: delay,
  })

  return (
    <animated.div
      className={styles.floatingCircle}
      style={{
        ...props,
        width: size,
        height: size,
        left: x,
        top: y,
        borderColor: `${color}`,
        background: background,
        filter: `blur(${blur})`,
        zIndex: zindex
      }}
    />
  );
}
export default FloatingCircle;