import "./TablaSalidas.css"
import { FormatoFecha } from "./../../utils/getFecha"
import { formatDinero } from "./../../utils/getDinero"
const TablaSalidas = (props) => {
    const { BloqueSalidas = [], moneda = "" } = props
    return (
        <div className="SalidasTable">
            <table>
                <tbody>
                    {
                        BloqueSalidas.map((bloque) => {
                            return (
                                <tr key={bloque.IdBloque}><th>
                                    {bloque.Descripcion}
                                    {
                                        bloque.Salidas.sort((a, b) => a.FechaSalida < b.FechaSalida).map((Salida, index) => {
                                            return (
                                                <div key={index}>
                                                {FormatoFecha({ date: Salida.FechaSalida.split("T")[0], formato: 'dd MMMM yyyy' })}
                                            </div>
                                            )
                                        })
                                    }
                                </th>
                                    <td>
                                        {formatDinero(bloque.Suplemento)} {moneda}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
export default TablaSalidas;